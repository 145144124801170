import React, { useEffect, useState } from 'react'
import { Alert, ButtonBase, Modal, Paper, Snackbar, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ActionButton from '../../components/ActionButton'
import Header from '../../components/Header'
import * as styles from './styles'
import { useForm } from 'react-hook-form'
import Input from '../../components/Input'
import DeleteIcon from '@mui/icons-material/Delete';
import AcceptButton from '../../components/AcceptButton';
import { API_URL } from '../../constants';
import Inputf from '../../components/Inputf'
import TransactionReport from '../../components/TransactionReport'
import { formatedDate } from '../../utils/dateFormat'
import { TAsset } from '../../types/interfaces'

interface ResponseData {
    client: string
    asset: string
    room: string
    event: string 
    transaction_type: string 
    transaction_id: string
    details: string
    created_by: string
    created_at: string
    asset_id: string
    asset_name: string
    room_name: string
    event_name: string
}

export default function SimpleReturn() {
    const navigate = useNavigate()
    const [idError, setIdError] = useState<boolean>(false)
    const [assetReturnedAlert, setAssetReturnedAlert] = useState<boolean>(false)
    const [assetError, setAssetError] = useState<boolean>(false)
    const [assetAlreadyAddedError, setAssetAlreadyAddedError] = useState<boolean>(false)
    const [selectedAssets, setSelectedAssets] = useState<Array<{asset_id:string, asset_name:string}>>([])
    const [pDFModal, setPDFModal] = useState<boolean>(false)
    const [responseData, setResponseData] = useState<Array<ResponseData>>([])
    const errorSound = new Audio('/assets/sounds/errorSound.mp3')

    const handleAddAssets = (value:string) => {
        if(selectedAssets.some((asset) => asset.asset_id === value)) {
            errorSound.play()
            return setAssetAlreadyAddedError(true)
        }
        fetch(API_URL+'/getAssetData/'+value,{
            method: 'GET',
            headers: {
                Authorization: 'Token '+localStorage.getItem('token')
            },
        })
        .then(response => response.json())
        .then((data:TAsset) => setSelectedAssets((arr) => [...arr,data]))
    }
    
    const handleReturn = () => {
        const idArray = selectedAssets.map((asset) => asset.asset_id)
        fetch(API_URL+'/simpleReturn', {
            method: 'POST',
            headers: {
                'Content-Type':  'application/json',
                Authorization: 'Token '+localStorage.getItem('token')
            },
            body: JSON.stringify({
                asset: idArray,
            })
        })
        .then((response) => {
            if (response.status === 201) {
                setAssetReturnedAlert(true)
                setPDFModal(true)
                response.json()
                .then((data) => setResponseData(data))
            }
            else {
                errorSound.play()
                setAssetError(true)
            }
        })
    }

  return (
    <div style={styles.newEventBackgroundStyle}>
        <Header/>
        <div style={styles.pageTopStyle}>
            <Typography style={styles.pageTitleStyle}>Registrar devolução</Typography>
            <ActionButton buttonText={'Voltar'} onClick={()=>{navigate(-1)}}/>
        </div>
        <Paper style={styles.formContainerStyle}>
            <div style={styles.formStyle}>
                <div style={styles.formRowStyle}>
                    <Typography style={styles.formLabelStyle}>Número de patrimônio</Typography>
                    <Inputf label={''} type={''} style={styles.inputStyle} onChange={(e:string) => handleAddAssets(e)} clear={true}/>
                </div>
                <div style={styles.showContainer}>
                    <div style={styles.formColumnStyle}>
                        <Typography style={styles.boldText}>Ativos selecionados:</Typography>
                        <div style={styles.itemsListStyle}>
                            {selectedAssets.map(asset => 
                                <Paper style={styles.listItemStyle}>
                                    <Typography>{asset.asset_id}</Typography>
                                    <Typography>{asset.asset_name}</Typography>
                                    <ButtonBase onClick={() => setSelectedAssets(arr => arr.filter(item => item !== asset))}>
                                        <DeleteIcon />
                                    </ButtonBase>
                                </Paper>
                            )}
                        </div>
                    </div>
                </div>
                <ActionButton buttonText={'Registrar'} onClick={() => handleReturn()}/>
            </div>
        </Paper>
        <Snackbar open={idError} autoHideDuration={3000} onClose={() => setIdError(false)}>
            <Alert severity="error">O campo número de patrimônio deve ser preenchido!</Alert>
        </Snackbar>
        <Snackbar open={assetReturnedAlert} autoHideDuration={2000} onClose={() => setAssetReturnedAlert(false)}>
            <Alert severity="success">Devolução registrada com sucesso!</Alert>
        </Snackbar>
        <Snackbar open={assetError} autoHideDuration={2000} onClose={() => setAssetError(false)}>
            <Alert severity="error">Houve um problema na devolução!</Alert>
        </Snackbar>
        <Snackbar open={assetAlreadyAddedError} autoHideDuration={2000} onClose={() => setAssetAlreadyAddedError(false)}>
            <Alert severity="error">Ativo já adicionado!</Alert>
        </Snackbar>
        <Modal
            open={pDFModal}
            onClose={() => setPDFModal(false)}
            style={styles.pdfModal}
        >   
            <TransactionReport 
                client={responseData[0]?.client} 
                dateTime={formatedDate(responseData[0]?.created_at || '')} 
                returnedAssets={{asset_name: responseData?.map((obj) => obj.asset_name), asset_id: responseData?.map((obj) => obj.asset_id)}} 
                registeredBy={responseData[0]?.created_by}
                details={responseData[0]?.details}
                withdrawAssets={{asset_name: [], asset_id: []}} 
                withdrawAccessories={{accessory_name: [], quantity: []}} 
                returnedAccessories={{accessory_name: [], accessory_id: []}} 
            />
        </Modal>
    </div>
  )
}