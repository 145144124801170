import React, { useState } from 'react'
import { TextField } from '@mui/material'
import { Controller, ControllerRenderProps, ControllerFieldState } from "react-hook-form";

interface Props {
    label: string
    name: string
    control: any
    type: string
    style: Object
    value?: string
    disabled?: boolean
    clear?: boolean
    maxLength?: number
}


export default function Input(props:Props) {
  return (
    <Controller
        name={props.name}
        control={props.control}
        render={({ field }) => 
            <TextField 
                style={props.style} 
                sx={{"& .MuiOutlinedInput-root": {"&.Mui-focused fieldset": {borderColor: "#025482"}}, "& label.Mui-focused": {color: "#025482"}}} 
                placeholder={props.label}
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.value)
                }}
                type={props.type}
                disabled={props.disabled}
                defaultValue={props.value}
                inputProps={{ maxLength: props.maxLength }}
            />
        }
    />
  )
}
