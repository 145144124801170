import React from 'react'

export default function Loading() {
  return (
    <div style={{
        'animation': 'all 2s linear infinite', 
        'display': 'flex', 
        'flexDirection': 'column', 
        'alignItems': 'center', 
        'justifyContent': 'center',
        'height': '100vh',
        'width': '100vw'
    }}>
        Carregando...
    </div>
  )
}
