import { Button } from '@mui/material'
import React from 'react'
import CSS from 'csstype'

const buttonStyle:CSS.Properties = {
  backgroundColor: '#025482',
  margin: '2vh'
}

interface Props {
  buttonText: string
}

export default function AcceptButton(props:Props) {
  return (
    <Button type='submit' variant='contained' style={buttonStyle}>{props.buttonText}</Button>
  )
}
