import React, { useState } from 'react'
import { TextField } from '@mui/material'

interface Props {
    style: Object
    label: string
    onChange: Function
    type: string
    clear?: boolean
    multiline?: boolean
    maxLength?: number
}

export default function Inputf(props: Props) {
  const [value, setValue] = useState('')
  return (
    <TextField 
        style={props.style} 
        sx={{"& .MuiOutlinedInput-root": {"&.Mui-focused fieldset": {borderColor: "#025482"}}, "& label.Mui-focused": {color: "#025482"}}} 
        placeholder={props.label}
        onChange={(e) => setValue(e.target.value)}
        value={value}
        onKeyDown={(e:React.KeyboardEvent<HTMLInputElement>) => {
            if(e.key==='Enter') {
                props.onChange((e.target as HTMLInputElement).value)
                if(props.clear) {
                  setValue('')
                }
            }
        }}
        type={props.type}
        multiline={props.multiline || false}
    />
  )
}
