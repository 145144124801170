import CSS from 'csstype'

export const eventsBackgroundStyle:CSS.Properties = {
    'position': 'absolute',
    'width': '100vw',
    'height': '100vh',
    'display': 'flex',
    'flexDirection': 'column'
}

export const pageTopStyle:CSS.Properties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '96vw',
    marginLeft: '2vw'
}

export const pageTitleStyle:CSS.Properties = {
    'color': '#000',
    fontSize: '24px',
    marginTop: '1vh'
}

export const modalTitleStyle:CSS.Properties = {
    'color': '#000',
    fontSize: '20px',
}

export const tableContainerStyle:CSS.Properties = {
    width: '96vw',
    height: '75vh',
    backgroundColor: '#FFF',
    marginLeft: '2vw',
    overflowY: 'scroll'
}

export const tableStyle:CSS.Properties = {
    borderCollapse: 'collapse',
    width: '94.7vw',
}

export const tableHeaderStyle:CSS.Properties = {
    borderBottom: '1px solid',
    height: '5vh',
    backgroundColor: '#E6E6E6'
}

export const tableBodyRowStyle:CSS.Properties = {
    borderCollapse: 'collapse',
    border: '1px',
    width: '96vw',
    textAlign: 'center',
    backgroundColor: '#FFF',
    borderBottom: '1px solid',
    borderColor: '#E6E6E6'
}

export const tableBodyRowStyleHover:CSS.Properties = {
    borderCollapse: 'collapse',
    border: '1px',
    width: '96vw',
    textAlign: 'center',
    backgroundColor: '#E6E6E6'
}

export const newEventModal:CSS.Properties = {
    paddingTop: '3vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '30vw',
    height: '60vh',
    marginTop: '20vh',
    marginLeft: '35vw'
}

export const actionButtonsStyle: CSS.Properties = {
    display: 'flex',
    width: '60vw',
    maxHeight: '10vh',
    justifyContent: 'space-between'
}

export const importModal: CSS.Properties = {
    display: 'flex',
    flexDirection: 'column',
    width: '40vw',
    height: '30vh',
    marginLeft: '30vw',
    marginTop: '35vh',
    justifyContent: 'center',
    alignItems: 'center'
}

export const divider: CSS.Properties = {
    marginBottom: '2vh',
    width: '30vw'
}

export const modalRow: CSS.Properties = {
    width: '20vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
}