import React, { useEffect, useState } from 'react'
import * as styles from './styles'
import Header from '../../components/Header'
import { ButtonBase, Modal, Paper, Typography, Snackbar, Alert, Divider, Checkbox } from '@mui/material'
import ActionButton from '../../components/ActionButton'
import { API_URL } from '../../constants'
import { formatedDate } from '../../utils/dateFormat'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete';
import StatusCircles from '../../components/StatusCircles'
import { TAsset, TEvent } from '../../types/interfaces'
import EditIcon from '@mui/icons-material/Edit';
import * as XLSX from 'xlsx';
import ConfirmModal from '../../components/ConfirmModal'
import SelectComponentf from '../../components/SelectComponentf'
import SearchBar from '../../components/SearchBar'
import Loading from '../../components/IsLoading'

export default function Assets() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [assets, setAssets] = useState<Array<TAsset>>([])
    const [filteredAssets, setFilteredAssets] = useState<Array<TAsset>>([])
    const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false)
    const [importModal, setImportModal] = useState<boolean>(false)
    const [importError, setImportError] = useState<boolean>(false)
    const [importSuccessAlert, setImportSuccessAlert] = useState<boolean>(false)
    const [deleteError, setDeleteError] = useState<boolean>(false)
    const [deletePermissionError, setDeletePermissionError] = useState<boolean>(false)
    const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false)
    const [update, setUpdate] = useState<number>(0)
    const [confirmModal, setConfirmModal] = useState<boolean>(false)
    const [confirmModalData, setConfirmModalData] = useState<{title: string, message: string, cancelCallback: Function, okCallback: Function}>()
    const [selectedAssets, setSelectedAssets] = useState<Array<string>>([])
    const [addToEventModal, setAddToEventModal] = useState<boolean>(false)
    const [events, setEvents] = useState<Array<TEvent>>([])
    const [selectedEvent, setSelectedEvent] = useState<string>('')
    const [addedAssetsToEventAlert ,setAddedAssetsToEventAlert] = useState<boolean>(false)
    const [addedAssetsToEventError ,setAddedAssetsToEventError] = useState<boolean>(false)
    const [page, setPage] = useState<number>(1)
    const [lastFetched, setLastFetched] = useState<number>()
    const [fetching, setFetching] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>()

    const handleExportAssets = async () => {
        setIsLoading(true)
        try{
            const data = []
            const allAssetsRequest = await fetch(API_URL+'/getAssetsList', {
                method: 'GET',
                headers: {
                    Authorization: 'Token '+localStorage.getItem('token')
                }
            })
            const allAssets = await allAssetsRequest.json()
            for (let asset of allAssets){
                const row = {
                    'Nome': asset.asset_name,
                    'Ativo': asset.asset_id,
                    'Criado em': asset.created_at,
                    'Criado por': asset.created_by.name,
                    'Evento': asset.event?.event_name || '',
                    'Sala': asset.room?.room_name || ''
                }
                data.push(row);
            }
            const ws = XLSX.utils.json_to_sheet(data)
            const wb: XLSX.WorkBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Lista Ativos');
            XLSX.writeFile(wb, 'sicars_ativos.xlsx')
        } catch (error: any) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleAddAssetsToEvent  = () => {
        fetch(API_URL+'/setAssetsToEvent',{
            method: 'POST',
            headers: {
                'Content-Type':  'application/json',
                Authorization: 'Token '+localStorage.getItem('token')
            },
            body: JSON.stringify({
                assets: selectedAssets,
                event: selectedEvent
            })
        })
        .then((response) => {
            if(response.ok) {
                setAddToEventModal(false)
                setAddedAssetsToEventAlert(true)
            }
            else {
                setAddedAssetsToEventError(true)
            }
        }) 
    }

    const handleDeleteAsset = (asset: TAsset) => {
        fetch(API_URL+'/deleteAsset',{
            method: 'POST',
            headers: {
                'Content-Type':  'application/json',
                Authorization: 'Token '+localStorage.getItem('token')
            },
            body: JSON.stringify({
                asset_pk: asset.pk
            })
        }) 
        .then(response => {
            if(response.ok) {
                setUpdate(n => n+1)
                setDeleteSuccess(true)
            }
            if(response.status === 401) {
                setDeletePermissionError(true)
            }
            if(response.status !== 401 && response.status !== 200) {
                setDeleteError(true)
            }
        })
    }

    const confirmDeleteAsset = (asset: TAsset) => {
        setConfirmModal(true)
        setConfirmModalData({
            title: 'Excluir ativo?', 
            message: 'Esta ação é irreversível', 
            cancelCallback: () => {setConfirmModal(false)}, 
            okCallback: () => {handleDeleteAsset(asset);setConfirmModal(false)}
        })
    }
    
    const readExcel = async (file:any) => {
        const importedData = new Promise(resolve => {
            const fileReader = new FileReader()
            fileReader.readAsArrayBuffer(file)
            fileReader.onload = (e: any) => {
                const result = e.target.result
                const wb = XLSX.read(result, { type: 'buffer' })
                const ws = wb.Sheets[wb.SheetNames[0]]
                const data = XLSX.utils.sheet_to_json(ws, {
                    raw: false,
                })
                resolve(data)
                
            }
            fileReader.onerror = (error => {
                return error
            })
        })
        return await importedData
    }

    const handleGenerateTemplate = () => {
        const ws = XLSX.utils.aoa_to_sheet([['Nome','Ativo']])
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Lista Ativos');
        XLSX.writeFile(wb, 'template_ativos.xlsx')
    }

    const handleImportAssets = (file: any) => {
        setIsLoading(true)
        readExcel(file)
        .then(async (results) => {
            const data = results as any[]
            let successCount = 0
            for (let result of data) {
                if (result.Nome && result.Ativo) {
                    await fetch(API_URL+'/setAsset',{
                        method: 'POST',
                        headers: {
                            'Content-Type':  'application/json',
                            Authorization: 'Token '+localStorage.getItem('token')
                        },
                        body: JSON.stringify({
                            type: 'create',
                            asset_id: result.Ativo,
                            asset_name: result.Nome,
                            event: {},
                        })
                    })
                    .then((response) => {
                        if(response.status !== 201) {
                            return setImportError(true)
                        }
                        else{
                            successCount += 1
                        }
                    })
                }
            }
            if (successCount === data.length) {
                setIsLoading(false)
                return setImportSuccessAlert(true)
            }
        })
    }

   
    useEffect(() => {
        fetch(API_URL+'/isUserAdmin',{
            method: 'GET',
            headers: {
              Authorization: 'Token '+localStorage.getItem('token')
            }
        })
        .then(response => {
        if (response.status === 200) {
            setIsUserAdmin(true)
        }
        })
        fetch(API_URL+'/getEventsList',{
            method: 'GET',
            headers: {
                Authorization: 'Token '+localStorage.getItem('token')
            }
        })
        .then(response => {
            if (response.status === 200) {
              response.json()
              .then((data:Array<TEvent>) => setEvents(data))
            }
        })
    },[])

    const handleGetAssets = async (page:number) => {
        try{
            setIsLoading(true)
            let actualData = assets
            const response = await fetch(API_URL+`/getAssetsWithPagination/${page}`,{
                method: 'GET',
                headers: {
                    Authorization: 'Token '+localStorage.getItem('token')
                }
            })
            if(response.ok) {
                const data: TAsset[] = await response.json()
                if(actualData && page !== 1) {
                    actualData?.push(...data)
                  }
                  else {
                    actualData = data
                }
                setAssets(actualData)
                setFilteredAssets(actualData)
                setLastFetched(data.length)
            }
            else {
                if (response.status == 401) {
                    navigate('/login')
                }
            }
        } catch (error: any) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleScroll = async (e:any) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target;
        const bufferHeight = 50;
        // Check if the user has scrolled to the bottom
        if (
            Math.floor(scrollTop + clientHeight) >= Math.floor(scrollHeight/2) - bufferHeight && 
            lastFetched && lastFetched >= 100 && 
            !fetching &&
            searchTerm !== ''
        ) {
          setFetching(true)
          await handleGetAssets(page+1);
          setPage(page => page+1)
          setFetching(false)
        }
    };

    const handleSearch = async (term: string) => {
        const trimmedSearchTerm = term.trim();
        const encodedSearchTerm = encodeURIComponent(trimmedSearchTerm);
        setSearchTerm(trimmedSearchTerm)
      
        try {
          if (term && term?.length >= 3) {
            const response = await fetch(API_URL+`/getAssetBySearchTerm/${encodedSearchTerm}`,{
                method: 'GET',
                headers: {
                    Authorization: 'Token '+localStorage.getItem('token')
                }
            })
            if (response.ok) {
                const data = await response.json()
                setFilteredAssets(data);
            }
            return
          }
          if (!term || term === '') {
            setSearchTerm(undefined)
            handleGetAssets(1)
          }
        } catch (error:any) {
          if (error?.name === 'AbortError') {
            // Ignore the request cancellation error
          } else {
            console.error('Error searching users:', error);
          }
        }
    }

    useEffect(() => {
        handleGetAssets(1)
    },[importSuccessAlert, importError, update, addedAssetsToEventAlert])

  return (
    <div style={styles.eventsBackgroundStyle}>
        <Header/>
        <Modal open={isLoading} onClose={() => setIsLoading(false)}>
            <Loading />
        </Modal>
        <div style={styles.pageTopStyle}>
            <Typography style={styles.pageTitleStyle}>Ativos</Typography>
            <SearchBar filteredCallback={(searchTerm: string) => handleSearch(searchTerm)}/>
            <div style={styles.actionButtonsStyle}>
                <ActionButton buttonText={'Cadastrar no evento'} onClick={()=>setAddToEventModal(true)}/>
                <ActionButton buttonText={'Exportar ativos'} onClick={()=>handleExportAssets()}/>
                <ActionButton buttonText={'Importar ativos'} onClick={()=>setImportModal(true)}/>
                <ActionButton buttonText={'Novo Ativo'} onClick={()=>{navigate('/novo-ativo')}}/>
            </div>
        </div>
        <Paper style={styles.tableContainerStyle} onScroll={handleScroll}>
            <table style={styles.tableStyle}>
                <tr style={styles.tableHeaderStyle}>
                    <th><Checkbox checked={selectedAssets.length>0} onChange={(e) => e.target.checked? setSelectedAssets(assets.map((asset) => asset.asset_id)) : setSelectedAssets([])}/></th>
                    <th>Nome</th>
                    <th>Número de patrimônio</th>
                    <th>Criado por</th>
                    <th>Evento</th>
                    <th>Sala</th>
                    <th>Status</th>
                    <th></th>
                    <th></th>
                </tr>
                {filteredAssets.map((asset:TAsset) =>                     
                    <tr style={styles.tableBodyRowStyle}>
                        <td><Checkbox onChange={(e) => e.target.checked ? setSelectedAssets(arr => [...arr, asset.asset_id]) : setSelectedAssets(arr => arr.filter(id => id !== asset.asset_id))} checked={selectedAssets.includes(asset.asset_id)}/></td>
                        <td>{asset.asset_name}</td>
                        <td>{asset.asset_id}</td>
                        <td>{asset.created_by.name}</td>
                        <td>{asset.event?.event_name}</td>
                        <td>{asset.room?.room_name}</td>
                        <td><StatusCircles status={asset.status}/></td>
                        {isUserAdmin? <td onClick={() => confirmDeleteAsset(asset)}><DeleteIcon /></td> : <td></td>}
                        <td onClick={() => navigate('/novo-ativo',{state: {assetToEdit: asset.asset_id}})}><EditIcon /></td>
                    </tr>
                )}
            </table>
        </Paper>
        <Modal
            open={importModal}
            onClose={() => setImportModal(false)}
        >
            <Paper style={styles.importModal}>
                <Typography>Importar arquivos via excel</Typography>
                <ActionButton buttonText='Baixar modelo' onClick={() => handleGenerateTemplate()}/>
                <Divider style={styles.divider}/>
                <input type='file' onChange={(e: any) => handleImportAssets(e.target.files[0])}/>
            </Paper>
        </Modal>
        <Snackbar open={importError} autoHideDuration={3000} onClose={() => setImportError(false)}>
            <Alert severity="error">Houve um erro na importação!</Alert>
        </Snackbar>
        <Snackbar open={importSuccessAlert} autoHideDuration={2000} onClose={() => setImportSuccessAlert(true)}>
            <Alert severity="success">Ativos importados com sucesso!</Alert>
        </Snackbar>
        <Snackbar open={deleteError} autoHideDuration={2000} onClose={() => setDeleteError(false)}>
            <Alert severity="error">Ocorreu um erro!</Alert>
        </Snackbar>
        <Snackbar open={deletePermissionError} autoHideDuration={2000} onClose={() => setDeletePermissionError(false)}>
            <Alert severity="error">Você não tem permissão para excluir ativos!</Alert>
        </Snackbar>
        <Snackbar open={deleteSuccess} autoHideDuration={2000} onClose={() => setDeleteSuccess(false)}>
            <Alert severity="success">Ativo excluído com sucesso!</Alert>
        </Snackbar>
        <Snackbar open={addedAssetsToEventAlert} autoHideDuration={2000} onClose={() => setAddedAssetsToEventAlert(false)}>
            <Alert severity="success">Ativos cadastrados no evento!</Alert>
        </Snackbar>
        <Snackbar open={addedAssetsToEventError} autoHideDuration={2000} onClose={() => setAddedAssetsToEventError(false)}>
            <Alert severity="error">Não foi possível cadastrar os ativos no evento!</Alert>
        </Snackbar>
        <Modal
            open={confirmModal}
            onClose={() => setConfirmModal(false)}
        >
            <ConfirmModal
                title={confirmModalData?.title}
                message={confirmModalData?.message}
                cancelCallback={confirmModalData?.cancelCallback}
                okCallback={confirmModalData?.okCallback}
            />
        </Modal>
        <Modal
            open={addToEventModal}
            onClose={() => setAddToEventModal(false)}
        >
            <Paper style={styles.importModal}>
                <Typography>Adicionar ativos selecionados a um evento?</Typography>
                <SelectComponentf 
                    label='' 
                    value='' 
                    values={events.reduce((acc, { event_name }) => ({ ...acc, [event_name]: event_name }), {})} 
                    onChange={(e: string) => setSelectedEvent(events.find(evnt => evnt.event_name == e)?.event_id ?? '')}
                />
                <div style={styles.modalRow}>
                    <ActionButton buttonText='Cancelar' onClick={() => setAddToEventModal(false)} />
                    <ActionButton buttonText='Adicionar' onClick={() => handleAddAssetsToEvent()}/>
                </div>
            </Paper>
        </Modal>
    </div>
  )
}