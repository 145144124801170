import React, { useEffect, useState } from 'react'
import * as styles from './styles'
import Header from '../../components/Header'
import { Paper, Typography } from '@mui/material'
import ActionButton from '../../components/ActionButton'
import { API_URL } from '../../constants'
import { formatedDate } from '../../utils/dateFormat'
import { useNavigate } from 'react-router-dom'
import { TAsset, TCreatedBy, TRoom, TEvent, TTransaction, TAcessoryTransaction, TAcessory } from '../../types/interfaces'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

type TTransactionByAsset = {
    asset: string,
    withdraw: string,
    returned: string,
    created_by: TCreatedBy,
    client: string
}

export default function Room() {
    const navigate = useNavigate()
    const [eventData, setEventData] = useState<TEvent>({pk: '', event_id: '', event_name: '', created_at: '', created_by: {name: ''}, event_status: 0})
    const [roomData, setRoomData] = useState<TRoom>({pk: '', room_id: '', room_name: '', created_at: '', created_by: {name: ''}, event: eventData, room_status: 0})
    const [transactions, setTransaction] = useState<Array<TTransaction>>([])
    const [transactionsByAsset, setTransactionsByAsset] = useState<Array<TTransactionByAsset>>([])
    const [acessoriesTransactions, setAcessoriesTransactions] = useState<Array<TAcessoryTransaction>>([])
    const [viewByAsset, setViewByAsset] = useState<boolean>(true)
    const typeValues: {[key: string]: string} = {'R': 'Retirada', 'D': 'Devolução'} 
    const [update, setUpdate] = useState<number>(0) 


    useEffect(() => {
        const room = sessionStorage.getItem('room')
        const event = sessionStorage.getItem('eventData')
        if(room) {
            setRoomData(JSON.parse(room))
        }
        if(event) {
            setEventData(JSON.parse(event))
        }
    },[])
    
    useEffect(() => {
        if(roomData.pk !== '') {
            fetch(API_URL+'/getRoomTransactions/'+roomData['pk'],{
                method: 'GET',
                headers: {
                    Authorization: 'Token '+localStorage.getItem('token')
                }
            })
            .then((response) => {
                if (response.status === 401) {
                    navigate('/login')
                }
                if (response.status === 200) {
                    response.json()
                    .then((data:Array<TTransaction>) => setTransaction(data))
                }
            })
            fetch(API_URL+'/getAccessoriesInRoom/'+roomData.pk,{
                method: 'GET',
                headers: {
                    Authorization: 'Token '+localStorage.getItem('token')
                }
            })
            .then((response) => response.json())
            .then((data) => setAcessoriesTransactions(data))
        }
    },[roomData,update])

    useEffect(() => {
        const assetData:Array<TTransactionByAsset> = []
        const assets:Array<string> = []
        transactions.map((transaction) => {
            assets.push(transaction.asset)
        })
        const uniqueAssets = assets.filter((value, index) => assets.indexOf(value) === index)
        uniqueAssets.map((asset) => {
            let withdraw:string = ''
            let returned:string = ''
            let created_by:TCreatedBy = {name:''}
            let client:string = ''
            const assetTransactions:Array<TTransaction> = transactions.filter((transaction) => {
                return transaction.asset === asset
            })
            assetTransactions.forEach((t) => {
                if(t.transaction_type === 'R') {
                    withdraw = t.created_at
                    created_by = t.created_by
                    client = t.client
                }
                if(t.transaction_type === 'D') {
                    returned = t.created_at
                }
            })
            assetData.push({
                asset: asset,
                withdraw: withdraw,
                returned: returned,
                created_by: created_by,
                client: client
            })
        })
        setTransactionsByAsset(assetData)
    },[transactions])


  return (
    <div style={styles.eventsBackgroundStyle}>
        <Header/>
        <div style={styles.pageTopStyle}>
            <div style={styles.pageTitleContainer}>
                <ArrowBackIcon onClick={() => navigate('/evento')}/>
                <Typography style={styles.pageTitleStyle}>
                    {eventData.event_name} - {roomData.room_name}
                </Typography>
            </div>
            <div style={styles.actionButtonsStyle}>
                {viewByAsset? <ActionButton buttonText={'Visualizar transações'} onClick={() => setViewByAsset(false)}/> : <ActionButton buttonText={'Visualizar ativos'} onClick={() => setViewByAsset(true)}/>}
                <ActionButton buttonText={'Registrar retirada/devolução'} onClick={() => navigate('/nova-transação')}/>
            </div>
        </div>
        <div style={styles.row}>
            <Paper style={styles.tableContainerStyle}>
                {viewByAsset?
                    <table style={styles.tableStyle}>
                        <tr style={styles.tableHeaderStyle}>
                            <th>Ativo</th>
                            <th>Retirada</th>
                            <th>Devolução</th>
                            <th>Criado por</th>
                            <th>Cliente</th>
                        </tr>
                        {transactionsByAsset.map((transaction) =>                     
                            <tr style={styles.tableBodyRowStyle}>
                                <td>{transaction.asset}</td>
                                <td>{transaction.withdraw? formatedDate(transaction.withdraw): '-'}</td>
                                <td>{transaction.returned? formatedDate(transaction.returned) > formatedDate(transaction.withdraw)? formatedDate(transaction.returned) : '-' : '-'}</td>
                                <td>{transaction['created_by']['name']}</td>
                                <td>{transaction['client']}</td>
                            </tr>
                        )}
                    </table>
                :
                    <table style={styles.tableStyle}>
                        <tr style={styles.tableHeaderStyle}>
                            <th>Tipo</th>
                            <th>Criado em</th>
                            <th>Criado por</th>
                            <th>Cliente</th>
                            <th>Ativo</th>
                        </tr>
                        {transactions.map((evnt) =>                     
                            <tr style={styles.tableBodyRowStyle}>
                                <td>{typeValues[evnt.transaction_type || '']}</td>
                                <td>{formatedDate(evnt.created_at)}</td>
                                <td>{evnt.created_by.name}</td>
                                <td>{evnt.client}</td>
                                <td>{evnt.asset}</td>
                            </tr>
                        )}
                    </table>
                }
            </Paper>
            <Paper style={styles.tableContainerStyle}>
                <table style={styles.tableStyle}>
                    <tr style={styles.tableHeaderStyle}>
                        <th>Acessório</th>
                        <th>Quantidade</th>
                        <th>Tipo</th>
                        <th>Criado em</th>
                        <th>Criado por</th>
                        <th>Cliente</th>
                    </tr>
                    {acessoriesTransactions.map((transaction) =>                     
                        <tr style={styles.tableBodyRowStyle}>
                            <td>{transaction.acessory}</td>
                            <td>{transaction.quantity}</td>
                            <td>{transaction.transaction_type}</td>
                            <td>{formatedDate(transaction.created_at)}</td>
                            <td>{transaction.created_by.name}</td>
                            <td>{transaction.client}</td>
                        </tr>
                    )}
                </table>               
            </Paper>
        </div>
    </div>
  )
}
