import React, { useEffect, useState } from 'react'
import { Alert, ButtonBase, Modal, Paper, Snackbar, Typography, TextField } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation, useNavigate } from 'react-router-dom'
import ActionButton from '../../components/ActionButton'
import Header from '../../components/Header'
import * as styles from './styles'
import { useForm } from 'react-hook-form'
import Input from '../../components/Input'
import AutocompleteField from '../../components/AutocompleteField'
import AutocompleteFieldnf from '../../components/AutocompleteFieldnf'
import AcceptButton from '../../components/AcceptButton';
import { API_URL } from '../../constants';
import Inputf from '../../components/Inputf';
import { TAcessory, TAsset, TEvent, TRoom } from '../../types/interfaces';

interface FormValues {
    event_name: string 
}


export default function NewEvent() {
    const location = useLocation()
    const navigate = useNavigate()
    const [availabeAssets, setAvailableAssets] = useState<Array<TAsset>>([])
    const [availableAcessories, setAvailableAcessories] = useState<Array<TAcessory>>([])
    const [selectedAssets, setSelectedAssets] = useState<string[]>([])
    const [nameError, setNameError] = useState<boolean>(false)
    const [assetsError, setAssetsError] = useState<boolean>(false)
    const [assetError, setAssetError] = useState<boolean>(false)
    const [assetAlreadyAddedError, setAssetAlreadyAddedError] = useState<boolean>(false)
    const [rooms, setRooms] = useState<string[]>([])
    const [acessories, setAcessories] = useState<Array<{acessory_name: string, quantity: number, available_quantity: number}>>([])
    const [roomError, setRoomError] = useState<boolean>(false)
    const [noRoomError, setNoRoomError] = useState<boolean>(false)
    const [eventName, setEventName] = useState<string>('')
    const [eventCreatedAlert, setEventCreatedAlert] = useState<boolean>(false)
    const [oSNumber, setOSNumber] = useState<string>('')
    const [uniqueOSError, setUniqueOSError] = useState(false)
    const [acessoryName, setAcessoryName] = useState<string>('')
    const [assets, setAssets] = useState<Array<TAsset>>()
    const errorSound = new Audio('/assets/sounds/errorSound.mp3')

    const handleAddRoom = (value:string) => {
        if(!rooms.includes(value)) {
            setRooms(data => [...data,value])
        }
        else {
            setRoomError(true)
        }
    }

    const handleSelectAsset = (value: string) => {
        if(!availabeAssets.map((asset) => asset.asset_id).includes(value)) {
            errorSound.play()
            return setAssetError(true)
        }
        else {
            if (selectedAssets.includes(value)) {
                errorSound.play()
                return setAssetAlreadyAddedError(true)
            }
            setSelectedAssets(data => [...data, value])
        }
    }

    const handleAddAcessory = (acessory_name: string, acessory_quantity: number) => {
        setAcessories(data => [...data,{acessory_name: acessory_name, quantity: acessory_quantity, available_quantity: acessory_quantity}])
    }

    const handleCreateNewEvent = () => {
        const formData = {
            event_name: eventName,
            event_id: oSNumber,
            event_assets: selectedAssets,
            event_rooms: rooms,
            acessories: acessories
        }
        if (formData.event_name === '' || !formData.event_name) {
            setNameError(true)
            return
        }
        /*if (formData.event_assets.length === 0 || !formData.event_assets) {
            setAssetsError(true)
            return
        }*/
        /*if (formData.event_rooms.length === 0 || !formData.event_rooms) {
            setNoRoomError(true)
            return
        }*/
        fetch(API_URL+'/setEvent',{
            method: 'POST',
            headers: {
                'Content-Type':  'application/json',
                Authorization: 'Token '+localStorage.getItem('token')
            },
            body: JSON.stringify({
                type: assets? 'update':'create',
                event_id: formData.event_id,
                event_name: formData.event_name,
                event_assets: formData.event_assets,
                event_rooms: formData.event_rooms,
                acessories: acessories
            })
        })
        .then((response) => {
            if(response.status === 201) {
                setEventCreatedAlert(true)
            }
            if(response.status === 400) {
                response.json()
                .then((data) => {
                    if (data.event_id.includes("event with this event id already exists.")) {
                        setUniqueOSError(true)
                    }
                })
            }
        })
           
    }

    useEffect(() => {
        setAvailableAssets([])
        fetch(API_URL+'/getAvailableAssets',{
            method: 'GET',
            headers: {
                Authorization: 'Token '+localStorage.getItem('token')
            }
        })
        .then(response => response.json())
        .then((data:Array<TAsset>) => {
            setAvailableAssets(data)
        })
    },[])

    useEffect(() => {
        if (location.state?.eventToEdit){
            fetch(API_URL+'/getAssetsList',{
                method: 'GET',
                headers: {
                    Authorization: 'Token '+localStorage.getItem('token')
                }
            })
            .then(response => response.json())
            .then((data:Array<TAsset>) => {
                setAssets(data)
            })

            fetch(API_URL+'/getEvent/'+location.state?.eventToEdit,{
                method: 'GET',
                headers: {
                    Authorization: 'Token '+localStorage.getItem('token')
                }
            })
            .then(response => {
                if(response.ok) {
                    response.json()
                    .then((data:{event_data: TEvent, asset_data: Array<TAsset>, accessory_data: Array<TAcessory>, room_data: Array<TRoom>}) => {
                        setEventName(data.event_data.event_name)
                        setOSNumber(data.event_data.event_id)
                        setSelectedAssets(data.asset_data.map((obj) => obj.asset_id))
                        setAcessories(data.accessory_data)
                        setRooms(data.room_data.map((obj) => obj.room_name))
                    })
                }
            })
        }
    },[location.state?.eventToEdit])

  return (
    <div style={styles.newEventBackgroundStyle}>
        <Header/>
        <div style={styles.pageTopStyle}>
            <Typography style={styles.pageTitleStyle}>Configurar novo evento</Typography>
            <ActionButton buttonText={'Voltar'} onClick={()=>{navigate(-1)}}/>
        </div>
        <Paper style={styles.formContainerStyle}>
            <div style={styles.row}>
            <div style={styles.formStyle}>
                <div style={styles.formRowStyle}>
                    <Typography style={styles.formLabelStyle}>*Nome do evento</Typography>
                    <TextField sx={{"& .MuiOutlinedInput-root": {"&.Mui-focused fieldset": {borderColor: "#025482"}}, "& label.Mui-focused": {color: "#025482"}}} style={styles.inputStyle} onChange={(e) => setEventName(e.target.value)} value={eventName}/>
                </div>
                <div style={styles.formRowStyle}>
                    <Typography style={styles.formLabelStyle}>*Número da OS</Typography>
                    <TextField sx={{"& .MuiOutlinedInput-root": {"&.Mui-focused fieldset": {borderColor: "#025482"}}, "& label.Mui-focused": {color: "#025482"}}}  style={styles.inputStyle} onChange={(e) => setOSNumber(e.target.value)} value={oSNumber} disabled={assets? true : false}/>
                </div>
                <div style={styles.formRowStyle}>
                    <Typography style={styles.formLabelStyle}>Adicionar ativos</Typography>
                    <Inputf label={'No. ativo'} type={''} onChange={(e:string) => handleSelectAsset(e)} style={styles.inputStyle} clear={true}/>
                </div>
                <div style={styles.formRowStyle}>
                    <Typography style={styles.formLabelStyle}>Adicionar salas</Typography>
                    <Inputf label={''} type={''} style={styles.inputStyle} onChange={(value:string) => handleAddRoom(value)}/>
                </div>
                <div style={styles.formRowStyle}>
                    <Typography style={styles.formLabelStyle}>Adicionar acessório</Typography>
                    <div>
                        <TextField sx={{"& .MuiOutlinedInput-root": {"&.Mui-focused fieldset": {borderColor: "#025482"}}, "& label.Mui-focused": {color: "#025482"}}}  key={'acessory_name'} type={''} style={styles.acessoryNameInputStyle} onChange={(e) => setAcessoryName(e.target.value)}/>
                        <Inputf label={'Qtde.'} type={'number'} style={styles.acessoryQuantityInputStyle} onChange={(value:number) => handleAddAcessory(acessoryName, value)}/>
                    </div>
                </div>
            </div>
            <div style={styles.showContainer}>
                <div style={styles.formColumnStyle}>
                    <Typography style={styles.boldText}>Ativos no evento:</Typography>
                    <div style={styles.itemsListStyle}>
                        {selectedAssets.map((asset) => 
                            <Paper style={styles.listItemStyle}>
                                <Typography>
                                    {assets? 
                                        assets.find((item) => item.asset_id === asset)?.asset_name
                                    : 
                                        availabeAssets.find((item) => item.asset_id === asset)?.asset_name
                                    }
                                </Typography>
                                <Typography>
                                    {assets? 
                                        assets.find((item) => item.asset_id === asset)?.asset_id
                                    : 
                                        availabeAssets.find((item) => item.asset_id === asset)?.asset_id
                                    }
                                </Typography>
                                <ButtonBase onClick={() => setSelectedAssets(arr => arr.filter(item => item !== asset))}>
                                    <DeleteIcon />
                                </ButtonBase>
                            </Paper>
                        )}
                    </div>
                </div>
                <div style={styles.formColumnStyle}>
                    <Typography style={styles.boldText}>Salas no evento:</Typography>
                    <div style={styles.itemsListStyle}>
                        {rooms.map((room) => 
                            <Paper style={styles.listItemStyle}>
                                <Typography>{room}</Typography>
                                <ButtonBase onClick={() => setRooms(arr => arr.filter(item => item !== room))}>
                                    <DeleteIcon />
                                </ButtonBase>
                            </Paper>
                        )}
                    </div>
                </div>
                <div style={styles.formColumnStyle}>
                    <Typography style={styles.boldText}>Acessórios no evento:</Typography>
                    <div style={styles.itemsListStyle}>
                        {acessories.map((acessory) => 
                            <Paper style={styles.listItemStyle}>
                                <Typography>{acessory.acessory_name}</Typography>
                                <Typography>{acessory.quantity}</Typography>
                                <ButtonBase onClick={() => setAcessories(arr => arr.filter(item => item !== acessory))}>
                                    <DeleteIcon />
                                </ButtonBase>
                            </Paper>
                        )}
                    </div>
                </div>
            </div>
            </div>
            <ActionButton buttonText={'Salvar'} onClick={() => handleCreateNewEvent()}/>
        </Paper>
        <Snackbar open={nameError} autoHideDuration={3000} onClose={() => setNameError(false)}>
            <Alert severity="error">O campo nome deve ser preenchido!</Alert>
        </Snackbar>
        <Snackbar open={assetsError} autoHideDuration={2000} onClose={() => setAssetsError(false)}>
            <Alert severity="error">Pelo menos um ativo deve ser adicionado ao evento!</Alert>
        </Snackbar>
        <Snackbar open={assetError} autoHideDuration={2000} onClose={() => setAssetError(false)}>
            <Alert severity="error">Ativo não encontrado!</Alert>
        </Snackbar>
        <Snackbar open={assetAlreadyAddedError} autoHideDuration={2000} onClose={() => setAssetAlreadyAddedError(false)}>
            <Alert severity="error">Ativo já adicionado!</Alert>
        </Snackbar>
        <Snackbar open={noRoomError} autoHideDuration={2000} onClose={() => setNoRoomError(false)}>
            <Alert severity="error">Pelo menos uma sala deve ser adicionada ao evento!</Alert>
        </Snackbar>
        <Snackbar open={roomError} autoHideDuration={2000} onClose={() => setRoomError(false)}>
            <Alert severity="error">Cada sala deve ter um nome único!</Alert>
        </Snackbar>
        <Snackbar open={eventCreatedAlert} autoHideDuration={2000} onClose={() => setEventCreatedAlert(false)}>
            <Alert severity="success">Evento criado com sucesso!</Alert>
        </Snackbar>
        <Snackbar open={uniqueOSError} autoHideDuration={2000} onClose={() => setUniqueOSError(false)}>
            <Alert severity="error">Já existe um evento com esta OS!</Alert>
        </Snackbar>
    </div>
  )
}
