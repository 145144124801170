import CSS from 'csstype'

export const loginBackgroundStyle:CSS.Properties = {
    'position': 'absolute',
    'width': '100vw',
    'height': '100vh',
    'backgroundImage': "url('/assets/images/loginBg.png')",
    'backgroundRepeat': 'no-repeat',
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'alignContent': 'center'
}

export const loginContainerStyle:CSS.Properties = {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'margin': 'auto',
    'minWidth': '400px',
    width: '30vw'
}

export const titleStyle:CSS.Properties = {
    'color': '#025482',
    'fontSize': '36px',
    'margin': '5vh',
}

export const formStyle: CSS.Properties = {
    marginBottom: '5vh',
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column'
}

export const inputRowStyle: CSS.Properties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '2vh'
}

export const iconStyle: CSS.Properties = {
    color: '#808080'
}

export const inputStyle: CSS.Properties = {
    width: '18vw',
    marginLeft: '2vw',
    minWidth: '200px'
}