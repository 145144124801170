import React, { useState, useEffect } from 'react'
import { useForm } from "react-hook-form";
import Input from '../../components/Input'
import { Alert, Paper, Snackbar, TextField, Typography } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import AcceptButton from '../../components/AcceptButton';
import * as styles from './styles'
import { API_URL } from '../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import ActionButton from '../../components/ActionButton';
import Header from '../../components/Header';
import SwitchComponent from '../../components/SwitchComponent';
import { TUser } from '../../types/interfaces';

type FormValues = {
    username: string
    password: string
    email: string
    first_name: string
    last_name: string
    type: string
  };
  

export default function AddUser() {
    const navigate = useNavigate()
    const location = useLocation()
    const [firstNameError, setFirstNameError] = useState<boolean>(false)
    const [usernameError, setUsernameError] = useState<boolean>(false)
    const [passwordError, setPasswordError] = useState<boolean>(false)
    const [userAlert, setUserAlert] = useState<boolean>(false)
    const { handleSubmit, control, setValue} = useForm<FormValues>()

    const handleRegisterNewUser = (data: FormValues) => {
        fetch(API_URL+'/createUser',{
            method: 'POST',
            headers: {
                'Content-Type':  'application/json',
                Authorization: 'Token '+localStorage.getItem('token')
            },
            body: JSON.stringify({
                username: data.username,
                password: data.password,
                email: data.email,
                first_name: data.first_name,
                last_name: data.last_name,
                type: data.type == 'on',
                request_type: location.state?.userToEdit? 'update' : 'create'
            })
        })
        .then((response) => {
            if(response.status === 201) {
                setUserAlert(true)
            }
        })
    }

    useEffect(() => {
        if (location.state?.userToEdit){
            fetch(API_URL+'/getUser/'+location.state?.userToEdit,{
                method: 'GET',
                headers: {
                    Authorization: 'Token '+localStorage.getItem('token')
                }
            })
            .then(response => response.json())
            .then((data:TUser) => {
                setValue('first_name',data.first_name)
                setValue('last_name',data.last_name)
                setValue('email', data.email)
                setValue('username', data.username)
            })
        }
    },[location.state?.eventToEdit])

  return (
    <div style={styles.newEventBackgroundStyle}>
        <Header/>
        <div style={styles.pageTopStyle}>
            <Typography style={styles.pageTitleStyle}>Adicionar usuários</Typography>
            <ActionButton buttonText={'Voltar'} onClick={()=>{navigate(-1)}}/>
        </div>
        <Paper style={styles.formContainerStyle}>
            <form onSubmit={handleSubmit(data => handleRegisterNewUser(data))} style={styles.formStyle}>
                <div style={styles.formRowStyle}>
                    <Typography style={styles.formLabelStyle}>Nome</Typography>
                    <Input label={''} name={'first_name'} type={''} control={control} style={styles.inputStyle}/>
                </div>
                <div style={styles.formRowStyle}>
                    <Typography style={styles.formLabelStyle}>Sobrenome</Typography>
                    <Input label={''} name={'last_name'} type={''} control={control} style={styles.inputStyle}/>
                </div>
                <div style={styles.formRowStyle}>
                    <Typography style={styles.formLabelStyle}>E-mail</Typography>
                    <Input label={''} name={'email'} type={''} control={control} style={styles.inputStyle}/>
                </div>
                <div style={styles.formRowStyle}>
                    <Typography style={styles.formLabelStyle}>Usuário</Typography>
                    <Input label={''} name={'username'} type={''} control={control} style={styles.inputStyle} disabled={location.state?.userToEdit? true:false}/>
                </div>
                <div style={styles.formRowStyle}>
                    <Typography style={styles.formLabelStyle}>Senha</Typography>
                    <Input label={''} name={'password'} type={'password'} control={control} style={styles.inputStyle}/>
                </div>
                <div style={styles.formRowStyle}>
                    <Typography style={styles.formLabelStyle}>Administrador</Typography>
                    <SwitchComponent name={'type'} control={control} style={styles.switchStyle} disabled={location.state?.userToEdit? true:false}/>
                </div>
                <AcceptButton buttonText={'Salvar'} />
            </form>
        </Paper>
        <Snackbar open={firstNameError} autoHideDuration={3000} onClose={() => setFirstNameError(false)}>
            <Alert severity="error">O campo primeiro nome deve ser preenchido!</Alert>
        </Snackbar>
        <Snackbar open={usernameError} autoHideDuration={3000} onClose={() => setUsernameError(false)}>
            <Alert severity="error">O campo contato deve ser preenchido!</Alert>
        </Snackbar>
        <Snackbar open={passwordError} autoHideDuration={3000} onClose={() => setPasswordError(false)}>
            <Alert severity="error">O campo contato deve ser preenchido!</Alert>
        </Snackbar>
        <Snackbar open={userAlert} autoHideDuration={3000} onClose={() => setUserAlert(false)}>
            <Alert severity="success">Usuário cadastrado com sucesso!</Alert>
        </Snackbar>
    </div>
  )
}

