import React from 'react'
import { Autocomplete, TextField } from '@mui/material'

interface Props {
    label: string
    values: string[]
    style: Object
    value: string
    onChangeCallback: Function
}

export default function AutocompleteFieldnf(props:Props) {
  return (
    <Autocomplete
        options={props.values}
        style={props.style}
        renderInput={(params) => <TextField {...params} value={props.value} style={props.style} label={props.label} onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => e.key == 'Enter'? props.onChangeCallback((e.target as HTMLInputElement).value) : {}}/>}
        sx={{"& .MuiOutlinedInput-root": {"&.Mui-focused fieldset": {borderColor: "#025482"}}, "& label.Mui-focused": {color: "#025482"}}}
        onChange={(e,value) => {value? props.onChangeCallback(value) : console.log('nothing selected')}}
        noOptionsText='Nenhuma opção disponível'
        value={props.value}
    />
  )
}
