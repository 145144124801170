import { Button, ButtonBase, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../constants';
import LogoutIcon from '@mui/icons-material/Logout';

interface Styles {
  [key: string]: React.CSSProperties;
  headerContainerStyle: React.CSSProperties;
  logoStyle: React.CSSProperties;
  menu: React.CSSProperties;
  menuItem: React.CSSProperties;
}

const styles:Styles = {
  headerContainerStyle: {
    backgroundColor: '#025482',
    width: '100%',
    height: '10vh',
    maxHeight: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  logoStyle: {
    maxHeight: '7vh',
    maxWidth: '25vw'
  },
  menu: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '1vw'
  },
  menuItem: {
    color: '#FFF',
  },
  button: {
    margin: '1vw',
    padding: '1vw',
    height: '10vh'
  },
  logoutButton: {
    color: '#FFF'
  }
}

export default function Header() {
  const navigate = useNavigate()
  const [isUserAdmin, setIsUserAdmin] = useState(false)

  const handleLogout = () => {
    localStorage.removeItem('token')
    navigate('/login')
  }

  useEffect(() => {
    fetch(API_URL+'/isUserAdmin',{
      method: 'GET',
      headers: {
        Authorization: 'Token '+localStorage.getItem('token')
      }
    })
    .then(response => {
      if (response.status === 200) {
        setIsUserAdmin(true)
      }
    })
  },[])
  return (
    <div style={styles.headerContainerStyle}>
        <ButtonBase style={styles.button}>
          <img onClick={() => navigate('/eventos')} src='/assets/images/logo.png' style={styles.logoStyle}/>
        </ButtonBase>
        <div style={styles.menu}>
          <ButtonBase style={styles.button} onClick={() => navigate('/eventos')} >
            <Typography style={styles.menuItem}>Eventos</Typography>
          </ButtonBase>
          <ButtonBase style={styles.button} onClick={() => navigate('/ativos')}>
            <Typography style={styles.menuItem}>Ativos</Typography>
          </ButtonBase>
          <ButtonBase style={styles.button} onClick={() => navigate('/clientes')}>
            <Typography style={styles.menuItem}>Clientes</Typography>
          </ButtonBase>
          {isUserAdmin?
            <ButtonBase style={styles.button} onClick={() => navigate('/usuarios')}>
              <Typography style={styles.menuItem}>Usuários</Typography>
            </ButtonBase>
            :
            <></>
          }
          <ButtonBase style={styles.button} onClick={() => handleLogout()}>
            <LogoutIcon style={styles.logoutButton}/>
          </ButtonBase>
        </div>
    </div>
  )
}
