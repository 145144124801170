import { Paper, Typography } from '@mui/material'
import React from 'react'
import ActionButton from '../ActionButton'

interface Props {
    title?: string
    message?: string
    cancelCallback?: Function
    okCallback?: Function
}

const styles = {
    container: {
        width: '20vw',
        height: '20vh',
        marginLeft: '40vw',
        marginTop: '40vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1vh'
    },
    title: {
        fontWeight: 'bold'
    },
    row: {
        display: 'flex',
        width: '20vw',
        justifyContent: 'center'
    }
  }

export default function ConfirmModal(props:Props) {
  return (
    <Paper style={styles.container}>
        <div>
            <Typography style={styles.title}>{props.title}</Typography>
            <Typography>{props.message}</Typography>
            <div style={styles.row}>
                <ActionButton buttonText='Não' onClick={()=> props.cancelCallback? props.cancelCallback() : {}}/>
                <ActionButton buttonText='Sim' onClick={()=> props.okCallback? props.okCallback() : {}}/>
            </div>
        </div>
    </Paper>
  )
}
