import { Button } from '@mui/material'
import React from 'react'
import CSS from 'csstype'

const mobileStyle: CSS.Properties = {
  backgroundColor: '#025482',
  marginTop: '2vh',
  marginBottom: '2vh',
  fontSize: '30%'
}

const styles: CSS.Properties = {
  backgroundColor: '#025482',
  marginTop: '2vh',
  marginBottom: '2vh',
}



interface Props {
  buttonText: string
  onClick: Function
}

export default function ActionButton(props:Props) {
  return (
    <Button variant='contained' style={window.screen.availWidth < window.screen.availHeight? mobileStyle : styles} onClick={() => props.onClick()}>{props.buttonText}</Button>
  )
}
