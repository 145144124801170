import React, { useEffect, useState } from 'react'
import * as styles from './styles'
import Header from '../../components/Header'
import { ButtonBase, Modal, Paper, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import ActionButton from '../../components/ActionButton'
import { API_URL } from '../../constants'
import { formatedDate } from '../../utils/dateFormat'
import { useNavigate } from 'react-router-dom'
import { TUser } from '../../types/interfaces';
import InTableButton from '../../components/InTableButton';
import EditIcon from '@mui/icons-material/Edit';


export default function Users() {
    const navigate = useNavigate()
    const [users, setUsers] = useState<Array<TUser>>([])
    const [update, setUpdate] = useState<number>(0)

    const handleInactivateUser = (id:string) => {
        fetch(API_URL+'/inactivateUser',{
            method: 'POST',
            headers: {
                'Content-Type':  'application/json',
                Authorization: 'Token '+localStorage.getItem('token')
            },
            body: JSON.stringify({
                user_id: id
            })
        })
        .then((response) => {
            setUpdate(n => n+1)
        })
    }

    useEffect(() => {
        fetch(API_URL+'/getUsersList',{
            method: 'GET',
            headers: {
                Authorization: 'Token '+localStorage.getItem('token')
            }
        })
        .then((response) => {
            if (response.status == 401) {
                navigate('/login')
            }
            else {
                response.json()
                .then((data:Array<TUser>) => setUsers(data))
            }
        })
    },[update])


  return (
    <div style={styles.eventsBackgroundStyle}>
        <Header/>
        <div style={styles.pageTopStyle}>
            <Typography style={styles.pageTitleStyle}>Usuários</Typography>
            <ActionButton buttonText={'Novo Usuário'} onClick={()=>{navigate('/novo-usuario')}}/>
        </div>
        <Paper style={styles.tableContainerStyle}>
            <table style={styles.tableStyle}>
                <tr style={styles.tableHeaderStyle}>
                    <th>Nome</th>
                    <th>Usuario</th>
                    <th>Email</th>
                    <th>Tipo</th>
                    <th>Status</th>
                    <th></th>
                </tr>
                {users.map((user) =>                     
                    <tr style={styles.tableBodyRowStyle}>
                        <td>{user.first_name + " " + user.last_name}</td>
                        <td>{user.username}</td>
                        <td>{user.email}</td>
                        <td>{user.type}</td>  
                        <InTableButton backgroundColor={user.status == 'inactive'? '#FDEDED' : '#EDF7ED'} onClick={() => handleInactivateUser(user.id)}>
                            <td>{user.status == 'inactive'? 'Inativo':'Ativo'}</td>
                        </InTableButton>
                        <td onClick={() => navigate('/novo-usuario',{state: {userToEdit: user.id}})}><EditIcon /></td>
                    </tr>
                )}
            </table>
        </Paper>
    </div>
  )
}
