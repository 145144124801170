import React, { useState } from 'react'
import { useForm } from "react-hook-form";
import Input from '../../components/Input'
import { Alert, Paper, Snackbar, TextField, Typography } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import AcceptButton from '../../components/AcceptButton';
import * as styles from './styles'
import { API_URL } from '../../constants';
import { useNavigate } from 'react-router-dom';

type FormValues = {
    username: string;
    password: string;
  };
  

export default function Login() {
    const navigate = useNavigate()
    const [userError, setUserError] = useState<boolean>(false)
    const { handleSubmit, control} = useForm<FormValues>()
    const handleLogin = (data: FormValues) => {
        fetch(API_URL+'/login/',{
            method: 'POST',
            headers: {
                'Content-Type':  'application/json',
            },
            body: JSON.stringify({
                username: data['username'],
                password: data['password']
            })
        })
        .then((response) => response.json())
        .then((token) => {
            if(token.token) {
                localStorage.setItem('token',token['token'])
                navigate('/eventos')
            }
            else {
                setUserError(true)
            }
        })
    }

  return (
    <div style={styles.loginBackgroundStyle}>
        <Paper style={styles.loginContainerStyle}>
            <Typography style={styles.titleStyle}>Login</Typography>
            <form style={styles.formStyle} onSubmit={handleSubmit(data => handleLogin(data))}>
                <div style={styles.inputRowStyle}>
                    <EmailIcon style={styles.iconStyle}/>
                    <Input
                        label='Usuário'
                        name="username"
                        control={control}
                        type={''}
                        style={styles.inputStyle}
                    />
                </div>
                <div style={styles.inputRowStyle}>
                    <LockIcon style={styles.iconStyle}/>
                    <Input
                        label='Senha'
                        name="password"
                        control={control}
                        type={'password'}
                        style={styles.inputStyle}
                    />
                </div>
                <AcceptButton buttonText='Entrar'/>
            </form>
        </Paper>
        <Snackbar open={userError} autoHideDuration={3000} onClose={() => setUserError(false)}>
            <Alert severity='error'>Usuário ou senha incorretos!</Alert>
        </Snackbar>
    </div>
  )
}
