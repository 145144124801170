import CSS from 'csstype'

export const newEventBackgroundStyle:CSS.Properties = {
    'position': 'absolute',
    'width': '100vw',
    'height': '100vh',
    'display': 'flex',
    'flexDirection': 'column'
}

export const pageTopStyle:CSS.Properties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '96vw',
    marginLeft: '2vw'
}

export const pageTitleStyle:CSS.Properties = {
    'color': '#000',
    fontSize: '24px',
    marginTop: '1vh'
}

export const formStyle: CSS.Properties = {
    width: '96vw',
    height: '75vh',
    backgroundColor: '#FFF',
    marginLeft: '2vw',
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
}

export const formContainerStyle: CSS.Properties = {
    width: '96vw',
    height: '75vh',
    backgroundColor: '#FFF',
    marginLeft: '2vw',
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'scroll'
}

export const formRowStyle: CSS.Properties = {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'left',
    marginTop: '2vh',
}
export const formColumnStyle: CSS.Properties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'left',
    marginTop: '2vh',
}

export const showContainer: CSS.Properties = {
    display: 'flex',
    flexDirection: 'row',
    height: '72vh',
    marginRight: '2vw',
    justifyContent: 'left'
}

export const formLabelStyle: CSS.Properties = {
    textAlign: 'left'
}

export const inputStyle: CSS.Properties = {
    width: '25vw'
}

export const acessoryNameInputStyle: CSS.Properties = {
    width: '15vw'
}

export const acessoryQuantityInputStyle: CSS.Properties = {
    width: '8vw',
    marginLeft: '2vw'
}

export const listItemStyle:CSS.Properties = {
    display: 'flex',
    flexDirection: 'row',
    padding: '2vh',
    width: '10vw',
    justifyContent: 'space-between',
    margin: '1vh'
}

export const itemsListStyle: CSS.Properties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '50vh',
    width: '20vw',
    overflowY: 'scroll'
}

export const boldText: CSS.Properties = {
    fontWeight: 'bold'
}

export const row:CSS.Properties = {
    width: '94vw',
    height: '65vh',
    backgroundColor: '#FFF',
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'row'
}