import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function Redirect() {
    const navigate = useNavigate()
    useEffect(() => {
        if (!sessionStorage.getItem('token')) {
            navigate('/login')
        }
        else {
            navigate('/eventos')
        }
    },[])
  return (<></>)
}
