import CSS from 'csstype'

export const eventsBackgroundStyle:CSS.Properties = {
    'position': 'absolute',
    'width': '100vw',
    'height': '100vh',
    'display': 'flex',
    'flexDirection': 'column',
}

export const pageTopStyle:CSS.Properties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '96vw',
    marginLeft: '2vw'
}

export const pageTitleStyle:CSS.Properties = {
    'color': '#000',
    fontSize: '20px',
    marginLeft: '1vw'
}

export const pageTitleContainer:CSS.Properties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
}

export const modalTitleStyle:CSS.Properties = {
    'color': '#000',
    fontSize: '20px',
}

export const tableContainerStyle:CSS.Properties = {
    width: '96vw',
    height: '50vh',
    backgroundColor: '#FFF',
    marginLeft: '1.4vw',
    overflowY: 'scroll',
    marginBottom: '2vw'
}

export const tableStyle:CSS.Properties = {
    borderCollapse: 'collapse',
    width: '94.7vw',
}

export const tableHeaderStyle:CSS.Properties = {
    borderBottom: '1px solid',
    height: '5vh',
    backgroundColor: '#E6E6E6'
}

export const tableBodyRowStyle:CSS.Properties = {
    borderCollapse: 'collapse',
    border: '1px',
    width: '96vw',
    textAlign: 'center',
    backgroundColor: '#FFF',
    borderBottom: '1px solid',
    borderColor: '#E6E6E6'
}

export const tableBodyRowStyleHover:CSS.Properties = {
    borderCollapse: 'collapse',
    border: '1px',
    width: '96vw',
    textAlign: 'center',
    backgroundColor: '#E6E6E6'
}

export const newTransactionModal:CSS.Properties = {
    paddingTop: '3vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '30vw',
    height: '55vh',
    marginTop: '20vh',
    marginLeft: '35vw'
}

export const formStyle: CSS.Properties = {
    marginBottom: '5vh',
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column'
}

export const formRowStyle: CSS.Properties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}

export const actionButtonsStyle: CSS.Properties = {
    display: 'flex',
    width: '45vw',
    justifyContent: 'space-between'
}

export const assetFieldStyle: CSS.Properties = {
    minWidth: '20vw'
}

export const row: CSS.Properties = {
    display: 'flex',
    flexDirection: 'column'
}