import CSS from 'csstype'

export const eventsBackgroundStyle:CSS.Properties = {
    'position': 'absolute',
    'width': '100vw',
    'height': '100vh',
    'display': 'flex',
    'flexDirection': 'column',
    overflowY: 'scroll'
}

export const pageTopStyle:CSS.Properties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '96vw',
    marginLeft: '2vw'
}

export const pageTitleStyle:CSS.Properties = {
    'color': '#000',
    fontSize: '24px',
    marginTop: '1vh'
}


export const tableContainerStyle:CSS.Properties = {
    width: '96vw',
    minHeight: '25vh',
    maxHeight: '50vh',
    backgroundColor: '#FFF',
    marginLeft: '1.4vw',
    overflowY: 'scroll',
    marginBottom: '2vw'
}

export const tableStyle:CSS.Properties = {
    borderCollapse: 'collapse',
    width: '94.7vw',
    tableLayout: 'fixed'
}

export const tableHeaderStyle:CSS.Properties = {
    borderBottom: '1px solid',
    height: '5vh',
    backgroundColor: '#E6E6E6'
}

export const tableBodyRowStyle:CSS.Properties = {
    borderCollapse: 'collapse',
    border: '1px',
    width: '96vw',
    textAlign: 'center',
    backgroundColor: '#FFF',
    borderBottom: '1px solid',
    borderColor: '#E6E6E6'
}

export const tableBodyRowStyleHover:CSS.Properties = {
    borderCollapse: 'collapse',
    border: '1px',
    width: '96vw',
    textAlign: 'center',
    backgroundColor: '#E6E6E6'
}

export const reportModal: CSS.Properties = {
    width: '50vw',
    height: '45vh',
    marginLeft: '25vw',
    marginTop: '25vh',
    paddingLeft: '1vw',
    paddingRight: '1vw',
    paddingTop: '1vh',
    paddingBottom: '1vh',
    display: 'flex',
    flexDirection: 'column',
}

export const pdfModal: CSS.Properties = {
    marginLeft: '15vw',
    marginTop: '5vh',
    height: '90vh',
    width: '70vw'
}

export const actionButtonsStyle: CSS.Properties = {
    display: 'flex',
    width: '45vw',
    maxHeight: '10vh',
    justifyContent: 'space-between'
}

export const modalBody: CSS.Properties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    width: '40vw',
    marginLeft: '5vw',
    alignItems: 'center'
} 

export const modalInput: CSS.Properties = {
    marginBottom: '1vh',
    width: '40vw',
    justifyContent: 'center',
    display: 'flex'
}

export const modalButton: CSS.Properties = {
    marginBottom: '1vh',
    display: 'flex',
    justifyContent: 'center',
}