import React from 'react'
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
        backgroundColor: '#FFF',
        alignItems: 'center'
	},
    header: {
        height: '6vh',
        width: '100vw',
        backgroundColor: '#025482',
    },
    logo: {
        width: '20vw',
        marginLeft: '2vw',
        marginTop: '1vh'
        
    },
	title: {
		marginTop: 10,
	},
    body: {
        marginTop: 20,
        width: '90vw',
    },
    bodyText: {
        textAlign: 'justify',
        fontSize: 12
    },
    viewer: {
        height: '90vh',
        width: '70vw',
    },
    form: {
        marginTop: '5vh',
        width: '90vw',
    },
    table: {
        flexDirection: 'row',
        width: '80vw',
        justifyContent: 'space-between',
        marginTop: 30,
        marginBottom: 10,
    },
    tableHeader: {
        fontSize: 14,
        fontWeight: 'bold',
        borderBottom: '1px',
        marginBottom: 20
    },
    tableColumn: {
        borderLeft: '1px'
    },
    signature: {
        marginTop: 30,
        flexDirection: 'column',
        alignItems: 'center'
    },
    signatureText: {
        marginTop: 10,
        textAlign: 'justify',
        fontSize: 12
    },
    row: {
        flexDirection: 'row'
    }

});


export default function TransactionReport(props) {
    return (
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.header}>
              <Image src='/assets/images/logo.png' style={styles.logo}/>
            </View>
            <View style={styles.title}>
              <Text>PROTOCOLO DE ENTREGA</Text>
            </View>
            <View style={styles.body}>
              <Text style={styles.bodyText}>Declaro ter recebido os NOTEBOOKS abaixo mencionados, os quais estão em perfeito estado de conservação e uso, comprometendo-me a devolvê-los da mesma forma que foram entregues.</Text>
            </View>
            <View style={styles.form}>
              <Text style={styles.bodyText}>Cliente: {props.client}</Text>
              <Text style={styles.bodyText}>Registrado por: {props.registeredBy}</Text>
              <Text style={styles.bodyText}>Data e horário: {props.dateTime}</Text>
              <Text style={styles.bodyText}>Sala: {props.roomName}</Text>
            </View>
            <View style={styles.table}>
              <View>
                <Text style={styles.tableHeader}>Equipamentos retirados</Text>
                {props.withdrawAssets.asset_name?
                  props.withdrawAssets.asset_name.map((asset, index) => (
                    <View style={styles.row} key={index}>
                      <Text style={styles.bodyText}>- {asset}</Text>
                      <Text style={styles.bodyText}> - {props.withdrawAssets.asset_id[index]}</Text>
                    </View>
                  ))
                  :
                  <></>
                }
              </View>
              <View style={styles.tableColumn}>
                <></>
              </View>
              <View>
                <Text style={styles.tableHeader}>Equipamentos devolvidos</Text>
                {props.returnedAssets.asset_name?
                  props.returnedAssets.asset_name.map((asset, index) => (
                    <View style={styles.row} key={index}>
                      <Text style={styles.bodyText}>- {asset}</Text>
                      <Text style={styles.bodyText}> - {props.returnedAssets.asset_id[index]}</Text>
                    </View>
                  ))
                  :
                  <></>
                }
              </View>
            </View>
            <View style={styles.table}>
              <View>
                <Text style={styles.tableHeader}>Acessórios retirados</Text>
                {props.withdrawAccessories.accessory_name?
                  props.withdrawAccessories.accessory_name.map((accessory, index) => (
                    <View style={styles.row} key={index}>
                      <Text style={styles.bodyText}>- {accessory}</Text>
                      <Text style={styles.bodyText}>- {props.withdrawAccessories.quantity[index]}</Text>
                    </View>
                  ))
                  :
                  <></>
                }
              </View>
              <View style={styles.tableColumn}>
                <></>
              </View>
              <View>
                <Text style={styles.tableHeader}>Acessórios devolvidos</Text>
                {props.returnedAccessories.accessory_name?
                  props.returnedAccessories.accessory_name.map((accessory, index) => (
                    <View style={styles.row} key={index}>
                      <Text style={styles.bodyText}>- {accessory}</Text>
                      <Text style={styles.bodyText}>- {props.returnedAccessories.quantity[index]}</Text>
                    </View>
                  ))
                  :
                  <></>
                }
              </View>
            </View>
            <View style={styles.body}>
              <Text style={styles.bodyText}>Observações: {props.details}</Text>
            </View>
            <View style={styles.signature}>
              <Text style={styles.signatureText}>Nome Legível: _____________________________</Text>
              <Text style={styles.signatureText}>RG: _____________________________</Text>
              <Text style={styles.signatureText}>_____________________________</Text>
              <Text style={styles.bodyText}>Assinatura e Carimbo</Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    );
  }