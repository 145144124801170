import React from 'react'
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { formatedDate } from '../../utils/dateFormat';

const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
        backgroundColor: '#FFF',
        alignItems: 'center'
	},
    header: {
        height: '6vh',
        width: '100vw',
        backgroundColor: '#025482',
    },
    logo: {
        width: '20vw',
        marginLeft: '2vw',
        marginTop: '1vh'
        
    },
	title: {
		marginTop: 10,
	},
    body: {
        marginTop: 20,
        width: '90vw',
    },
    bodyText: {
        textAlign: 'justify',
        fontSize: 12
    },
    tableBodyText: {
        textAlign: 'justify',
        fontSize: 12,
        width: '20vw',
        textAlign: 'center',
        paddingHorizontal: '2vw'
    },
    viewer: {
        height: '90vh',
        width: '70vw',
    },
    form: {
        marginTop: '5vh',
        width: '90vw',
    },
    table: {
        flexDirection: 'row',
        width: '80vw',
        justifyContent: 'space-between',
        marginTop: 30,
        marginBottom: 10,
    },
    tableHeader: {
        fontSize: 14,
        fontWeight: 'bold',
        borderBottom: '1px',
        width: '20vw',
        textAlign: 'center'
    },
    tableHeaderRow: {
        marginBottom: 20,
        flexDirection: 'row',
    },
    tableRow: {
        marginBottom: 20,
        flexDirection: 'row',
    },
    signature: {
        marginTop: 30,
        flexDirection: 'column',
        alignItems: 'center'
    },
    signatureText: {
        marginTop: 10,
        textAlign: 'justify',
        fontSize: 12
    },
    row: {
        flexDirection: 'row'
    }

});


export default function EventReportByClient(props) {
    return (
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.header}>
              <Image src='/assets/images/logo.png' style={styles.logo}/>
            </View>
            <View style={styles.title}>
              <Text>Relatório/Protocolo - {props.event?.event_name}</Text>
            </View>
            <View style={styles.form}>
              <Text style={styles.bodyText}>Evento: {props.event.event_name}</Text>
              <Text style={styles.bodyText}>Cliente: {props.client || ''}</Text>
            </View>
            <View style={styles.body}>
              <Text style={styles.bodyText}>Declaro ter recebido os NOTEBOOKS abaixo mencionados, os quais estão em perfeito estado de conservação e uso, comprometendo-me a devolvê-los da mesma forma que foram entregues.</Text>
            </View>
            <View style={styles.table}>
              <View>
                <View style={styles.tableHeaderRow}>
                    <Text style={styles.tableHeader}>Ativo</Text>
                    <Text style={styles.tableHeader}>Retirada</Text>
                    <Text style={styles.tableHeader}>Devolução</Text>
                    <Text style={styles.tableHeader}>Sala</Text>
                </View>
                {props.transactions?
                    <View>
                    {
                    props.transactions.map((transaction, index) => (
                        <View style={styles.tableRow} key={index}>
                        <Text style={styles.tableBodyText}>{props.assets.filter((obj) => obj.asset_id == transaction.asset)[0]['asset_name']} - {props.assets.filter((obj) => obj.asset_id == transaction.asset)[0]['asset_id']}</Text>
                        {transaction.transaction_type === 'R'?
                            <Text style={styles.tableBodyText}>{formatedDate(transaction.created_at)}</Text>
                        :
                            <Text style={styles.tableBodyText}>-</Text>
                        }
                        {transaction.transaction_type === 'D'?
                            <Text style={styles.tableBodyText}>{formatedDate(transaction.created_at)}</Text>
                        :
                            <Text style={styles.tableBodyText}>-</Text>
                        }
                        <Text style={styles.tableBodyText}>{props.rooms.filter(obj => obj.pk === transaction.room)[0]['room_name']}</Text>
                        </View>
                    ))
                    }
                    </View>
                :
                    <></>
                }
                </View>
            </View>
            <View style={styles.table}>
              <View>
                <View style={styles.tableHeaderRow}>
                    <Text style={styles.tableHeader}>Acessório</Text>
                    <Text style={styles.tableHeader}>Quantidade</Text>
                    <Text style={styles.tableHeader}>Retirada</Text>
                    <Text style={styles.tableHeader}>Devolução</Text>
                    <Text style={styles.tableHeader}>Sala</Text>
                </View>
                {props.accTransactions?
                    <View>
                    {
                    props.accTransactions.map((transaction, index) => (
                        <View style={styles.tableRow} key={index}>
                        <Text style={styles.tableBodyText}>{props.accessories.filter((obj) => obj.acessory_name === transaction.acessory)[0]['acessory_name']}</Text>
                        <Text style={styles.tableBodyText}>{transaction.quantity}</Text>
                        {transaction.transaction_type === 'Retirada'?
                            <Text style={styles.tableBodyText}>{formatedDate(transaction.created_at)}</Text>
                        :
                            <Text style={styles.tableBodyText}>-</Text>
                        }
                        {transaction.transaction_type === 'Devolução'?
                            <Text style={styles.tableBodyText}>{formatedDate(transaction.created_at)}</Text>
                        :
                            <Text style={styles.tableBodyText}>-</Text>
                        }
                        <Text style={styles.tableBodyText}>{props.rooms.filter(obj => obj.pk === transaction.room)[0]['room_name']}</Text>
                        </View>
                    ))
                    }
                    </View>
                :
                    <></>
                }
            </View>
            </View>
            <View style={styles.signature}>
              <Text style={styles.signatureText}>Nome Legível: _____________________________</Text>
              <Text style={styles.signatureText}>RG: _____________________________</Text>
              <Text style={styles.signatureText}>_____________________________</Text>
              <Text style={styles.bodyText}>Assinatura e Carimbo</Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    );
  }