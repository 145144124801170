import React, { ReactNode } from 'react'
import { Paper } from '@mui/material'

interface Props {
    children: ReactNode
    backgroundColor: string
    onClick: Function
}


export default function InTableButton(props: Props) {
    const styles = {
        padding: '1%',
        margin: '2%',
        backgroundColor: props.backgroundColor,
        display: 'flex',
        justifyContent: 'center',
    }
  return (
    <Paper style={styles} elevation={0} onClick={() => props.onClick()}>
        {props.children}
    </Paper>
  )
}
