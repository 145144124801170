import CSS from 'csstype'

export const newEventBackgroundStyle:CSS.Properties = {
    'position': 'absolute',
    'width': '100vw',
    'height': '100vh',
    'display': 'flex',
    'flexDirection': 'column'
}

export const pageTopStyle:CSS.Properties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '96vw',
    marginLeft: '2vw'
}

export const pageTitleStyle:CSS.Properties = {
    'color': '#000',
    fontSize: '24px',
    marginTop: '1vh'
}

export const formStyle: CSS.Properties = {
    width: '96vw',
    height: '75vh',
    backgroundColor: '#FFF',
    marginLeft: '2vw',
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
}

export const formContainerStyle: CSS.Properties = {
    width: '96vw',
    height: '75vh',
    backgroundColor: '#FFF',
    marginLeft: '2vw',
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
}

export const formRowStyle: CSS.Properties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2vh',
}

export const formLabelStyle: CSS.Properties = {
    marginRight: '5vw',
    width: '10vw'
}

export const inputStyle: CSS.Properties = {
    width: '25vw'
}

export const listItemStyle:CSS.Properties = {
    display: 'flex',
    flexDirection: 'row',
    padding: '2vh',
    width: '20vw',
    justifyContent: 'space-between',
    margin: '1vh'
}

export const itemsListStyle: CSS.Properties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '2vh',
    height: '40vh',
    width: '40vw',
    overflowY: 'scroll'
}

export const boldText: CSS.Properties = {
    fontWeight: 'bold'
}

export const formColumnStyle: CSS.Properties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2vh',
}

export const showContainer: CSS.Properties = {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '2vw'
}

export const pdfModal: CSS.Properties = {
    marginLeft: '15vw',
    marginTop: '5vh',
    height: '90vh',
    width: '70vw'
}