import React from 'react'
import CSS from 'csstype'
import CircleIcon from '@mui/icons-material/Circle';

const grayStatusCircle: CSS.Properties = {
    color: '#808080'
}

const greenStatusCircle: CSS.Properties = {
    color: '#4BB543'
}

const redStatusCircle: CSS.Properties = {
    color: '#d0342c'
}


export default function StatusCircles(status:{status: number}) {
const statusCircleColors:{[key: number]:Object} = {0: grayStatusCircle, 1: greenStatusCircle, 2: redStatusCircle}
  return (
    <CircleIcon style={statusCircleColors[status.status]}/>
  )
}
