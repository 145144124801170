import { Switch } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

interface Props {
    name: string
    control: any
    style: Object
    disabled?: boolean
}


export default function SwitchComponent(props:Props) {
  return (
    <Controller
        name={props.name}
        control={props.control}
        render={({ field }) => 
            <Switch
                style={props.style} 
                {...field}
                onChange={(e) => field.onChange(e.target.value)}
                disabled={props.disabled}
            />
        }
    />
  )
}
