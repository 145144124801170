import React, { useEffect, useState } from 'react'
import { Alert, ButtonBase, Modal, Paper, Snackbar, Typography, TextField, Button, Divider } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom'
import ActionButton from '../../components/ActionButton'
import Header from '../../components/Header'
import * as styles from './styles'
import { useForm } from 'react-hook-form'
import Input from '../../components/Input'
import AutocompleteField from '../../components/AutocompleteField'
import AutocompleteFieldnf from '../../components/AutocompleteFieldnf'
import AcceptButton from '../../components/AcceptButton';
import { API_URL } from '../../constants';
import Inputf from '../../components/Inputf';
import SelectComponent from '../../components/SelectComponent';
import TransactionReport from '../../components/TransactionReport';
import { PDFViewer } from '@react-pdf/renderer';
import { date } from 'yup';
import { formatedDate } from '../../utils/dateFormat';
import { TAsset, TClient, TRoom, TEvent, TTransaction, TAcessory } from '../../types/interfaces';
import SwitchComponentf from '../../components/SwitchComponentf';
import ConfirmModal from '../../components/ConfirmModal';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import BarcodeReader from '../../components/BarcodeReader';

export default function NewTransaction() {
    const navigate = useNavigate()
    const [selectedAssets, setSelectedAssets] = useState<Array<{'asset':string,'status':number}>>([])
    const [selectedAcessories, setSelectedAcessories] = useState<Array<{accessory_name: string, transaction_type: string, quantity: number}>>([])
    const [selectedClient, setSelectedClient] = useState<string>('')
    const [eventData, setEventData] = useState<TEvent>({pk: '', event_id: '', event_name: '', created_at: '', created_by: {name: ''}, event_status: 0})
    const [roomData, setRoomData] = useState<TRoom>({pk: '', room_id: '', room_name: '', created_at: '', created_by: {name: ''}, event: eventData, room_status: 0})
    const [unknownError, setUnknownError] = useState<boolean>(false)
    const [assetError, setAssetError] = useState<boolean>(false)
    const [differentRoomError, setDifferentRoomError]= useState<boolean>(false)
    const [accessoryIdFieldError, setAccessoryIdFieldError] = useState<boolean>(false)
    const [accessoryQuantityFieldError, setAccessoryQuantityFieldError] = useState<boolean>(false)
    const [successAlert, setSuccessAlert] = useState<boolean>(false)
    const [availableAssets, setAvailableAssets] = useState<{[key: string]: TAsset}>({})
    const [availableAcessories, setAvailableAcessories] = useState<{[key: string]: TAcessory}>({})
    const [clients, setClients] = useState<{[key: string]:string}>({})
    const [pDFModal, setPDFModal] = useState<boolean>(false)
    const [responseData, setResponseData] = useState<{asset_data:Array<{client: string, asset: string, room: string, event: string, transaction_type: string, transaction_id: string, created_by: string, created_at: string, details: string}>,accessory_data:Array<{client: string, acessory: string, room: string, event: string, transaction_type: string, transaction_id: string, created_by: string, created_at: string, quantity: string, details: string}>}>()
    const [accessoryIdField, setAccessoryIdField] = useState<string>('')
    const [accessoryQuantityField, setAccessoryQuantityField] = useState<number>(0)
    const [details, setDetails] = useState<string>('')
    const [assetAlreadyAddedError, setAssetAlreadyAddedError] = useState<boolean>(false)
    const [generateReport, setGenerateReport] = useState<boolean>(false)
    const [confirmTransaction, setConfirmTransaction] = useState<boolean>(false)
    const [cameraOpen, setCameraOpen] = useState<boolean>(false)
    const errorSound = new Audio('/assets/sounds/errorSound.mp3')

    const handleOpenCamera = () => {
        setCameraOpen(true)
    }

    const handleSelectAccessory = (e: string) => {
        if (accessoryIdField == '') {
            errorSound.play()
            return setAccessoryIdFieldError(true)
        }
        if (accessoryQuantityField == 0) {
            errorSound.play()
            return setAccessoryQuantityFieldError(true)
        }
        setSelectedAcessories(arr => [...arr, {accessory_name: accessoryIdField, transaction_type: e, quantity: accessoryQuantityField}])
    }

    const handleSelectAsset = async (value: string) => {
        const status = await calculateAssetStatus(value)
        if(!Object.values(availableAssets).map((asset) => asset.asset_id).includes(value)) {
            errorSound.play()
            return setAssetError(true)
        }
        if (selectedAssets.map(obj => obj.asset).includes(value)) {
            errorSound.play()
            return setAssetAlreadyAddedError(true)
        }
        setSelectedAssets(data => [...data, {asset: value, status: status}])
    }

    const handleNewTransaction:Function = () => {
        fetch(API_URL+'/setTransaction',{
            method: 'POST',
            headers: {
                'Content-Type':  'application/json',
                Authorization: 'Token '+localStorage.getItem('token')
            },
            body: JSON.stringify({
                client: parseInt(selectedClient),
                asset: selectedAssets.map((asset) => asset.asset),
                room: parseInt(roomData.pk),
                event: parseInt(eventData.pk),
                acessory: selectedAcessories,
                details: details
            })
        })
        .then((response) => {
            if(response.status !== 201) {
                if(response.status === 400){
                    response.json()
                    .then((error) => {
                        if(error === 'Assets must be returned in the same room'){
                            setDifferentRoomError(true)
                        }
                    })
                }
                setUnknownError(true)
            }
            else {
                response.json()
                .then((data) => {
                    if (generateReport){
                        setResponseData(data)
                        setPDFModal(true)
                    }
                    setSuccessAlert(true)
                    setSelectedAcessories([])
                    setSelectedAssets([])
                })
            }
        })
    }
    
    useEffect(() => {
        const room = sessionStorage.getItem('room')
        const event = sessionStorage.getItem('eventData')
        if(room) {
            setRoomData(JSON.parse(room))
        }
        if(event) {
            setEventData(JSON.parse(event))
        }
        const localEventData = JSON.parse(event || '')
        fetch(API_URL+'/getClientsInEvent/'+localEventData.pk,{
            method: 'GET',
            headers: {
                Authorization: 'Token '+localStorage.getItem('token')
    
            }
        })
        .then((response) => response.json())
        .then((data) => {
            const obj:{[key: string]: string} = {}
            data.map((client:TClient) => {
                obj[client.pk.toString()] = client.name
            })
            setClients(obj)
        })
    },[])

    useEffect(() => {
        if(eventData.pk !== '') {
            fetch(API_URL+'/getAvailableAssetsInEvent/'+eventData.pk,{
                method: 'GET',
                headers: {
                    Authorization: 'Token '+localStorage.getItem('token')
    
                },
            })
            .then((response) => response.json())
            .then((data) => {
                const objAsset:{[key: string]: TAsset} = {}
                data['asset'].map((asset:TAsset) => {
                    objAsset[asset.pk.toString()] = asset
                })
                setAvailableAssets(objAsset)
                const objAcessory:{[key: string]: TAcessory} = {}
                console.log(data['acessory'])
                data['acessory'].map((acessory:TAcessory) => {
                    objAcessory[acessory.pk.toString()] = acessory
                })
                setAvailableAcessories(objAcessory)
            })
        }
    },[eventData])

    const calculateAssetStatus = async (asset:string) => {
        const promise = await fetch(API_URL+'/getAssetStatus/'+asset,{
            method: 'GET',
            headers: {
                Authorization: 'Token '+localStorage.getItem('token')
            }
        })
        const data:number = await promise.json()
        return data
    }

  return (
    <div style={styles.newEventBackgroundStyle}>
        <Header/>
        <div style={styles.pageTopStyle}>
            <div>
                <Typography style={styles.pageTitleStyle}>Registrar retirada/devolução</Typography>
                <Typography style={styles.pageSubtitleStyle}>{eventData.event_name} - {roomData.room_name}</Typography>
            </div>
            <ActionButton buttonText={'Voltar'} onClick={()=>{navigate(-1)}}/>
        </div>
        <Paper style={styles.formContainerStyle}>
            <div style={styles.row}>
            <div style={styles.formStyle}>
                <div style={styles.formRowStyle}>
                   <Typography style={styles.formLabelStyle}>*Cliente</Typography>
                    <AutocompleteFieldnf label={''} value={clients[selectedClient]} values={Object.values(clients)} style={styles.assetFieldStyle} onChangeCallback={(e:string) => setSelectedClient(Object.keys(clients).find(key => clients[key] === e) || '')}/>
                </div>
                <Divider light style={styles.divider}/>
                <div style={styles.formRowStyle}>
                    <Typography style={styles.formLabelStyle}>Número de patrimônio</Typography>
                    <div style={styles.row}>
                        <Inputf label={'No. ativo'} type={''} onChange={(e:string) => handleSelectAsset(e)} style={styles.assetFieldStyle} clear={true}/>
                        <CameraAltIcon onClick={() => handleOpenCamera()} style={styles.cameraIcon}/>
                    </div>
                </div>
                <Divider light style={styles.divider}/>
                <div style={styles.formRowStyle}>
                   <Typography style={styles.formLabelStyle}>Acessório</Typography>
                   <AutocompleteFieldnf label={''} value={accessoryIdField} values={availableAcessories? Object.values(availableAcessories).map(obj => {return obj.acessory_name}) : []}  style={styles.assetFieldStyle} onChangeCallback={(e:string) => {setAccessoryIdField(e)}}/>
                   {accessoryIdField !== ''? <Typography style={styles.helperText}>Quantidade disponível: {Object.values(availableAcessories)?.filter(obj => obj.acessory_name == accessoryIdField)[0]?.available_quantity}</Typography> : <></>}
                </div> 
                <div style={styles.formRowStyle}>
                   <Typography style={styles.formLabelStyle}>Quantidade</Typography>
                   <TextField sx={{"& .MuiOutlinedInput-root": {"&.Mui-focused fieldset": {borderColor: "#025482"}}, "& label.Mui-focused": {color: "#025482"}}} label={''} style={styles.assetFieldStyle} type='number' inputProps={{min:0}} onChange={(e) => setAccessoryQuantityField(parseInt(e.target.value))}/>
                </div>
                <div style={styles.formRowStyle}>
                   <Typography style={styles.formLabelStyle}>Tipo de transação</Typography>
                   <AutocompleteFieldnf label={''} value={''} values={['Retirada','Devolução']}  style={styles.assetFieldStyle} onChangeCallback={(e:string) => handleSelectAccessory(e)}/>
                </div>
                <Divider light style={styles.divider}/>
                <div style={styles.formRowStyle}>
                   <Typography style={styles.formLabelStyle}>Observação</Typography>
                   <TextField sx={{"& .MuiOutlinedInput-root": {"&.Mui-focused fieldset": {borderColor: "#025482"}}, "& label.Mui-focused": {color: "#025482"}}} label={''} type={''} onChange={(e) => setDetails(e.target.value)} style={styles.assetFieldStyle} multiline={true}/>
                </div>
                <Divider light style={styles.divider}/>
                <div style={styles.formRowStyle}>
                   <Typography style={styles.formLabelStyle}>Gerar Relatório</Typography>
                   <SwitchComponentf onChange={(e:string) => {setGenerateReport(!generateReport)}} style={styles.switchStyle}/>
                </div>
            </div>
            <div style={styles.showContainer}>
                <div style={styles.formColumnStyle}>
                    <Typography style={styles.boldText}>Ativos selecionados:</Typography>
                    <div style={styles.itemsListStyle}>
                        {selectedAssets.map(asset => 
                            <Paper style={styles.listItemStyle}>
                                <Typography>{Object.values(availableAssets).find(obj => obj.asset_id === asset.asset)?.asset_name}</Typography>
                                <Typography>{Object.values(availableAssets).find(obj => obj.asset_id === asset.asset)?.asset_id}</Typography>
                                <Typography>{asset.status !== 2? 'Retirada' : 'Devolução'}</Typography>
                                <ButtonBase onClick={() => setSelectedAssets(arr => arr.filter(item => item !== asset))}>
                                    <DeleteIcon />
                                </ButtonBase>
                            </Paper>
                        )}
                    </div>
                </div>
                <div style={styles.formColumnStyle}>
                    <Typography style={styles.boldText}>Acessórios selecionados:</Typography>
                    <div style={styles.itemsListStyle}>
                        {selectedAcessories.map(acessory => 
                            <Paper style={styles.listItemStyle}>
                                <Typography>{acessory.accessory_name}</Typography>
                                <Typography>{acessory.transaction_type}</Typography>
                                <Typography>{acessory.quantity}</Typography>
                                <ButtonBase onClick={() => setSelectedAcessories(arr => arr.filter(item => item !== acessory))}>
                                    <DeleteIcon />
                                </ButtonBase>
                            </Paper>
                        )}
                    </div>
                </div>
            </div>
            </div>
            <div style={styles.buttonContainer}>
                <ActionButton buttonText={'Registrar'} onClick={() => setConfirmTransaction(true)}/>
            </div>
        </Paper>
        <Snackbar open={successAlert} autoHideDuration={3000} onClose={() => setSuccessAlert(false)}>
            <Alert severity="success">Transação registrada!</Alert>
        </Snackbar>
        <Snackbar open={assetError} autoHideDuration={3000} onClose={() => setAssetError(false)}>
            <Alert severity="error">Ativo não encontrado ou indisponível!</Alert>
        </Snackbar>
        <Snackbar open={assetAlreadyAddedError} autoHideDuration={3000} onClose={() => setAssetAlreadyAddedError(false)}>
            <Alert severity="error">Ativo já adicionado!</Alert>
        </Snackbar>
        <Snackbar open={unknownError} autoHideDuration={3000} onClose={() => setUnknownError(false)}>
            <Alert severity="error">Ocorreu um erro!</Alert>
        </Snackbar>
        <Snackbar open={differentRoomError} autoHideDuration={3000} onClose={() => setDifferentRoomError(false)}>
            <Alert severity="error">O ativo deve ser devolvido na mesma sala em que foi retirado!</Alert>
        </Snackbar>
        <Snackbar open={accessoryIdFieldError} autoHideDuration={3000} onClose={() => setAccessoryIdFieldError(false)}>
            <Alert severity="error">Selecione um acessório!</Alert>
        </Snackbar>
        <Snackbar open={accessoryQuantityFieldError} autoHideDuration={3000} onClose={() => setAccessoryQuantityFieldError(false)}>
            <Alert severity="error">A quantidade de acessórios deve ser maior que 1!</Alert>
        </Snackbar>
        <Modal
            open={pDFModal}
            onClose={() => setPDFModal(false)}
            style={styles.pdfModal}
        >   
            <TransactionReport 
                client={clients[responseData?.asset_data[0]?.client || 0]} 
                dateTime={formatedDate(responseData?.asset_data[0]?.created_at || '')} 
                withdrawAssets={{asset_name: responseData?.asset_data?.filter(obj => obj.transaction_type === 'R').map(obj => availableAssets[obj.asset].asset_name), asset_id: responseData?.asset_data?.filter(obj => obj.transaction_type === 'R').map(obj => availableAssets[obj.asset].asset_id)}} 
                returnedAssets={{asset_name: responseData?.asset_data?.filter(obj => obj.transaction_type === 'D').map(obj => availableAssets[obj.asset].asset_name), asset_id: responseData?.asset_data?.filter(obj => obj.transaction_type === 'D').map(obj => availableAssets[obj.asset].asset_id)}} 
                withdrawAccessories={{accessory_name: responseData?.accessory_data?.filter(obj => obj.transaction_type === 'Retirada').map(obj => availableAcessories[obj.acessory].acessory_name), quantity: responseData?.accessory_data?.filter(obj => obj.transaction_type === 'Retirada').map(obj => obj.quantity)}} 
                returnedAccessories={{accessory_name: responseData?.accessory_data?.filter(obj => obj.transaction_type === 'Devolução').map(obj => availableAcessories[obj.acessory].acessory_name), accessory_id: responseData?.accessory_data?.filter(obj => obj.transaction_type === 'Devolução').map(obj => obj.quantity)}} 
                registeredBy={responseData?.asset_data[0]?.created_by}
                details={responseData?.asset_data[0]?.details === ''? responseData?.accessory_data[0]?.details : responseData?.asset_data[0].details}
                roomName={roomData.room_name}
            />
        </Modal>
        <Modal
            open={confirmTransaction}
            onClose={() => setConfirmTransaction(false)}
        >
            <ConfirmModal title='Confirmar transação' message='Deseja confirmar esta transação?' okCallback={() => {handleNewTransaction();setConfirmTransaction(false)}} cancelCallback={() => setConfirmTransaction(false)}/>
        </Modal>
        <Modal
            open={cameraOpen}
            onClose={() => setCameraOpen(false)}
        >
            <BarcodeReader decodedText={(decodedText: string) => {handleSelectAsset(decodedText); console.log(decodedText);setCameraOpen(false)}}/>
        </Modal>
    </div>
  )
}
