import React, { useEffect, useState } from 'react'
import { Alert, ButtonBase, Modal, Paper, Snackbar, Typography } from '@mui/material'   
import { useLocation, useNavigate } from 'react-router-dom'
import ActionButton from '../../components/ActionButton'
import Header from '../../components/Header'
import * as styles from './styles'
import { useForm } from 'react-hook-form'
import Input from '../../components/Input'
import AcceptButton from '../../components/AcceptButton';
import { API_URL } from '../../constants';
import { TAsset } from '../../types/interfaces';

interface FormValues {
    asset_name: string
    asset_id: string
}

export default function NewAsset() {
    const location = useLocation()
    const navigate = useNavigate()
    const { handleSubmit, control, setValue} = useForm<FormValues>()
    const [nameError, setNameError] = useState(false)
    const [idError, setIdError] = useState(false)
    const [assetCreatedAlert, setAssetCreatedAlert] = useState(false)
    const [uniqueAssetError, setUniqueAssetError] = useState(false)
    const [assetData, setAssetData] = useState<TAsset>()

    const handleCreateNewAsset = (data: FormValues) => {
        const formData = data
        if (formData.asset_name === '' || !formData.asset_name) {
            setNameError(true)
            return
        }
        if (formData.asset_id === '' || !formData.asset_id) {
            setIdError(true)
            return
        }
        fetch(API_URL+'/setAsset',{
            method: 'POST',
            headers: {
                'Content-Type':  'application/json',
                Authorization: 'Token '+localStorage.getItem('token')
            },
            body: JSON.stringify({
                type: assetData? 'update':'create',
                asset_id: formData.asset_id,
                asset_name: formData.asset_name,
                event: {},
            })
        })
        .then((response) => {
            if(response.status === 201) {
                setAssetCreatedAlert(true)
                setValue(
                    'asset_name', data.asset_name
                )
                setValue(
                    'asset_id', ''
                )
            }
            if(response.status === 400) {
                response.json()
                .then((data) => {
                    if (data.asset_id.includes("asset with this asset id already exists.")) {
                        setUniqueAssetError(true)
                    }
                })
            }
        }) 
    }

    useEffect(() => {
        if (location.state?.assetToEdit) {
            fetch(API_URL+'/getAssetData/'+location.state?.assetToEdit,{
                method: 'GET',
                headers: {
                    Authorization: 'Token '+localStorage.getItem('token')
                }
            })
            .then(response => {
                if(response.ok) {
                    response.json()
                    .then((data: TAsset) => {
                        setAssetData(data)
                        setValue(
                            'asset_name', data.asset_name
                        )
                        setValue(
                            'asset_id', data.asset_id
                        )
                    })
                }
            })
        }
    },[location.state?.assetToEdit])

  return (
    <div style={styles.newEventBackgroundStyle}>
        <Header/>
        <div style={styles.pageTopStyle}>
            <Typography style={styles.pageTitleStyle}>Configurar novo ativo</Typography>
            <ActionButton buttonText={'Voltar'} onClick={()=>{navigate(-1)}}/>
        </div>
        <Paper style={styles.formContainerStyle}>
            <form onSubmit={handleSubmit(data => handleCreateNewAsset(data))} style={styles.formStyle}>
                <div style={styles.formRowStyle}>
                    <Typography style={styles.formLabelStyle}>*Nome do ativo</Typography>
                    <Input label={''} name={'asset_name'} type={''} control={control} style={styles.inputStyle} value={assetData?.asset_name}/>
                </div>
                <div style={styles.formRowStyle}>
                    <Typography style={styles.formLabelStyle}>*Número de patrimônio</Typography>
                    <Input label={''} name={'asset_id'} type={''} control={control} style={styles.inputStyle} disabled={assetData? true : false} clear={true} maxLength={4} value={assetData?.asset_id}/>
                </div>
                <AcceptButton buttonText={'Salvar'} />
            </form>
        </Paper>
        <Snackbar open={nameError} autoHideDuration={3000} onClose={() => setNameError(false)}>
            <Alert severity="error">O campo nome deve ser preenchido!</Alert>
        </Snackbar>
        <Snackbar open={idError} autoHideDuration={3000} onClose={() => setIdError(false)}>
            <Alert severity="error">O campo número de patrimônio deve ser preenchido!</Alert>
        </Snackbar>
        <Snackbar open={assetCreatedAlert} autoHideDuration={2000} onClose={() => setAssetCreatedAlert(false)}>
            <Alert severity="success">Ativo criado com sucesso!</Alert>
        </Snackbar>
        <Snackbar open={uniqueAssetError} autoHideDuration={2000} onClose={() => setUniqueAssetError(false)}>
            <Alert severity="error">Já existe um ativo com este número de patrimônio!</Alert>
        </Snackbar>
    </div>
  )
}