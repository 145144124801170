import React, { useEffect, useState } from 'react'
import * as styles from './styles'
import Header from '../../components/Header'
import { ButtonBase, Modal, Paper, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import ActionButton from '../../components/ActionButton'
import { API_URL } from '../../constants'
import { formatedDate } from '../../utils/dateFormat'
import { useNavigate } from 'react-router-dom'
import { TClient } from '../../types/interfaces';
import EditIcon from '@mui/icons-material/Edit';


export default function Clients() {
    const navigate = useNavigate()
    const [clients, setClients] = useState<Array<TClient>>([])
    const [update, setUpdate] = useState<number>(0)

    useEffect(() => {
        fetch(API_URL+'/getClientsList',{
            method: 'GET',
            headers: {
                Authorization: 'Token '+localStorage.getItem('token')
            }
        })
        .then((response) => {
            if (response.status == 401) {
                navigate('/login')
            }
            else {
                response.json()
                .then((data:Array<TClient>) => setClients(data))
            }
        })
    },[update])

    


  return (
    <div style={styles.eventsBackgroundStyle}>
        <Header/>
        <div style={styles.pageTopStyle}>
            <Typography style={styles.pageTitleStyle}>Clientes</Typography>
            <ActionButton buttonText={'Novo Cliente'} onClick={()=>{navigate('/novo-cliente')}}/>
        </div>
        <Paper style={styles.tableContainerStyle}>
            <table style={styles.tableStyle}>
                <tr style={styles.tableHeaderStyle}>
                    <th>Nome</th>
                    <th>Contato</th>
                    <th>Criado em</th>
                    <th>Criado por</th>
                    <th>Evento</th>
                    <th></th>
                </tr>
                {clients.map((client) =>                     
                    <tr style={styles.tableBodyRowStyle}>
                        <td>{client.name}</td>
                        <td>{client.contact}</td>
                        <td>{formatedDate(client.created_at)}</td>
                        <td>{client.added_by.name}</td> 
                        <td>{client.event?.event_name}</td>  
                        <td onClick={() => navigate('/novo-cliente',{state: {clientToEdit: client.client_id}})}><EditIcon /></td>
                    </tr>
                )}
            </table>
        </Paper>
    </div>
  )
}
