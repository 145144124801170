import React from 'react'
import Select from '@mui/material/Select';
import { Controller } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';

interface Values {
  [key: string]: string;
}

interface Props {
  label: string | null
  value: string
  values: Values
  onChange: Function
}

const styles = {
  minWidth: '20vw'
}

export default function SelectComponentf(props:Props) {
  return (
    <Select 
        style={styles}
        label={props.label}
        onChange={(e) => props.onChange(e.target.value)}
    >
        {Object.keys(props.values).map((t) => 
            <MenuItem value={t}>{props.values[t]}</MenuItem>
        )}
    </Select>
  )
}
