import { InputAdornment, TextField } from '@mui/material'
import React from 'react'
import SearchIcon from '@mui/icons-material/Search';

interface Props {
    filteredCallback: Function
}

export default function SearchBar(props: Props) {
    const handleSearch = (searchTerm: string) => {
      props.filteredCallback(searchTerm)
    }
  return (
    <TextField 
        InputProps={{
            startAdornment: (
            <InputAdornment position="start">
                <SearchIcon style={{height: '3vh'}}/>
            </InputAdornment>
            ),
        }}
        sx={{'& .MuiInput-underline:before': {
            borderBottomColor: '#025482',
          },
          '& .MuiInput-underline:hover:before': {
            borderBottomColor: '#025482', 
          },
          '& .MuiInput-underline.Mui-focused:before': {
            borderBottomColor: '#025482',
          },
        }} 
        onChange={(e) => handleSearch(e.target.value)}
        style={{height: '5vh'}}
        variant='standard'
    />
  )
}
