import { Switch } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'

interface Props {
    onChange: Function
    style: Object
}


export default function SwitchComponentf(props:Props) {
  return (
    <Switch
        style={props.style} 
        onChange={(e) => props.onChange(e.target.value)}
    />
  )
}
