import React, { useEffect, useState } from 'react'
import { Alert, ButtonBase, Modal, Paper, Snackbar, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation, useNavigate } from 'react-router-dom'
import ActionButton from '../../components/ActionButton'
import Header from '../../components/Header'
import * as styles from './styles'
import { useForm } from 'react-hook-form'
import Input from '../../components/Input'
import AutocompleteField from '../../components/AutocompleteField'
import AutocompleteFieldnf from '../../components/AutocompleteFieldnf'
import AcceptButton from '../../components/AcceptButton';
import { API_URL } from '../../constants';
import { TClient, TEvent } from '../../types/interfaces';

interface FormValues {
    name: string
    contact: string
    event: string
}

export default function NewClient() {
    const location = useLocation()
    const navigate = useNavigate()
    const { handleSubmit, control, setValue} = useForm<FormValues>()
    const [nameError, setNameError] = useState(false)
    const [nameAlreadyExistsError, setNameAlreadyExistsError] = useState<boolean>(false)
    const [contactError, setContactError] = useState(false)
    const [clientCreatedAlert, setClientCreatedAlert] = useState(false)
    const [events, setEvents] = useState<Array<TEvent>>([])

    const handleCreateNewClient = (data: FormValues) => {
        const formData = data
        if (formData.name === '' || !formData.name) {
            setNameError(true)
            return
        }
        if (formData.contact === '' || !formData.contact) {
            setContactError(true)
            return
        }
        fetch(API_URL+'/setClient',{
            method: 'POST',
            headers: {
                'Content-Type':  'application/json',
                Authorization: 'Token '+localStorage.getItem('token')
            },
            body: JSON.stringify({
                type: location.state?.clientToEdit? 'update' : 'create',
                client_id: formData.name.toLowerCase().replace(' ',''),
                name: formData.name,
                contact: formData.contact,
                event: events.find(obj => obj.event_name === formData.event)?.event_id
            })
        })
        .then((response) => {
            if(response.status === 201) {
                setClientCreatedAlert(true)
            }
            if(response.status === 400) {
                response.json()
                .then((data) => {
                    if (data.client_id.includes("client with this client id already exists.")) {
                        setNameAlreadyExistsError(true)
                    }
                })
            }
        })
    }

    useEffect(() => {
        fetch(API_URL+'/getEventsList',{
            method: 'GET',
            headers: {
                Authorization: 'Token '+localStorage.getItem('token')
            }
        })
        .then((response) => response.json())
        .then((data) => setEvents(data))
    },[])

    useEffect(() => {
        if (location.state?.clientToEdit) {
            fetch(API_URL+'/getClientData/'+location.state?.clientToEdit,{
                method: 'GET',
                headers: {
                    Authorization: 'Token '+localStorage.getItem('token')
                }
            })
            .then(response => {
                if(response.ok) {
                    response.json()
                    .then((data: TClient) => {
                        setValue(
                            'name', data.name
                        )
                        setValue(
                            'contact', data.contact
                        )
                        setValue(
                            'event', data.event.event_name
                        )
                    })
                }
            })
        }
    },[location.state?.clientToEdit])

  return (
    <div style={styles.newEventBackgroundStyle}>
        <Header/>
        <div style={styles.pageTopStyle}>
            <Typography style={styles.pageTitleStyle}>Cadastrar novo cliente</Typography>
            <ActionButton buttonText={'Voltar'} onClick={()=>{navigate(-1)}}/>
        </div>
        <Paper style={styles.formContainerStyle}>
            <form onSubmit={handleSubmit(data => handleCreateNewClient(data))} style={styles.formStyle}>
                <div style={styles.formRowStyle}>
                    <Typography style={styles.formLabelStyle}>*Nome do cliente</Typography>
                    <Input label={''} name={'name'} type={''} control={control} style={styles.inputStyle}/>
                </div>
                <div style={styles.formRowStyle}>
                    <Typography style={styles.formLabelStyle}>*Contato</Typography>
                    <Input label={''} name={'contact'} type={''} control={control} style={styles.inputStyle}/>
                </div>
                <div style={styles.formRowStyle}>
                    <Typography style={styles.formLabelStyle}>Evento</Typography>
                    <AutocompleteField name={'event'} label={''} value='' values={events.map(obj => obj.event_name)} control={control} style={styles.inputStyle}/>
                </div>
                <AcceptButton buttonText={'Salvar'} />
            </form>
        </Paper>
        <Snackbar open={nameError} autoHideDuration={3000} onClose={() => setNameError(false)}>
            <Alert severity="error">O campo nome deve ser preenchido!</Alert>
        </Snackbar>
        <Snackbar open={nameAlreadyExistsError} autoHideDuration={3000} onClose={() => setNameAlreadyExistsError(false)}>
            <Alert severity="error">Já existe um cliente com este nome!</Alert>
        </Snackbar>
        <Snackbar open={contactError} autoHideDuration={3000} onClose={() => setContactError(false)}>
            <Alert severity="error">O campo contato deve ser preenchido!</Alert>
        </Snackbar>
        <Snackbar open={clientCreatedAlert} autoHideDuration={3000} onClose={() => setClientCreatedAlert(false)}>
            <Alert severity="success">Cliente cadastrado com sucesso!</Alert>
        </Snackbar>
    </div>
  )
}