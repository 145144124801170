import React, { useEffect, useState } from 'react'
import * as styles from './styles'
import Header from '../../components/Header'
import { ButtonBase, Modal, Paper, Typography } from '@mui/material'
import ActionButton from '../../components/ActionButton'
import { API_URL } from '../../constants'
import { formatedDate } from '../../utils/dateFormat'
import { useNavigate } from 'react-router-dom'
import StatusCircles from '../../components/StatusCircles'
import { TRoom, TEvent, TAsset, TAcessory, TTransaction, TAcessoryTransaction, TClient } from '../../types/interfaces'
import SwitchComponent from '../../components/SwitchComponent'
import SwitchComponentf from '../../components/SwitchComponentf'
import SelectComponent from '../../components/SelectComponent'
import SelectComponentf from '../../components/SelectComponentf'
import EventReportByClient from '../../components/EventReportByClient'
import EventReportByAsset from '../../components/EventReportByAsset'
import EventReport from '../../components/EventReport'
import AutocompleteFieldnf from '../../components/AutocompleteFieldnf'
import Loading from '../../components/IsLoading'


export default function Event() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [eventData, setEventData] = useState<TEvent>({pk: '', event_id: '', event_name: '', created_at: '', created_by: {name: ''}, event_status: 0})
    const [rooms, setRooms] = useState<Array<TRoom>>([{pk: '',room_id: '',room_name:'',created_at: '',created_by:{name: ''},event:eventData, room_status: 0}])
    const [assets, setAssets] = useState<Array<TAsset>>([])
    const [accessories, setAccessories] = useState<Array<TAcessory>>([])
    const [transactionsInEvent, setTransactionsInEvent] = useState<Array<TTransaction>>([])
    const [reportModal, setReportModal] = useState<boolean>(false)
    const [report, setReport] = useState<boolean>(false)
    const [reportType, setReportType] = useState<string>('')
    const reportTypeValues:{[key: string]: string} = {'general': 'Geral', 'client': 'Por cliente', 'asset': 'Por Ativo'}
    const [clientToGenerateReport, setClientToGenerateReport] = useState('')
    const [accTransactionsInEvent, setAccTransactionsInEvent] = useState<Array<TAcessoryTransaction>>([])
    const [clientsInEvent, setClientsInEvent] = useState<Array<TClient>>([])

    const handleEnterRoom = (pk: string) => {
        const roomData = rooms[rooms.map(i => i.pk).indexOf(pk)]
        sessionStorage.setItem('room',JSON.stringify(roomData))
        sessionStorage.setItem('eventData',JSON.stringify(eventData))
        navigate('/sala')
    }

    useEffect(() => {
        setIsLoading(true)
        const currentEvent = sessionStorage.getItem('event')
        fetch(API_URL+'/getCurrentEvent/'+currentEvent,{
            method: 'GET',
            headers: {
                Authorization: 'Token '+localStorage.getItem('token')

            }
        })
        .then((response) => {
            if (response.status == 401) {
                navigate('/login')
            }
            else {
                response.json()
                .then((data:TEvent) => setEventData(data))
            }
        })
        fetch(API_URL+'/getEventRooms/'+currentEvent,{
            method: 'GET',
            headers: {
                Authorization: 'Token '+localStorage.getItem('token')
            }
        })
        .then((response) => response.json())
        .then((data) => setRooms(data))
        fetch(API_URL+'/getAvailableAssetsInEvent/'+currentEvent,{
            method: 'GET',
            headers: {
                Authorization: 'Token '+localStorage.getItem('token')
            }
        })
        .then((response) => response.json())
        .then((data: {asset:Array<TAsset>, acessory:Array<TAcessory>}) => {
            setAssets(data.asset)
            setAccessories(data.acessory)
        })
        fetch(API_URL+'/getClientsInEvent/'+currentEvent,{
            method: 'GET',
            headers: {
                Authorization: 'Token '+localStorage.getItem('token')
            }
        })
        .then((response) => response.json())
        .then((data:Array<TClient>) => {
            setClientsInEvent(data)
        })
        setIsLoading(false)
    },[])

    useEffect(() => {   
        if (reportType !== '') {
            fetch(API_URL+'/getTransactionsInEvent/'+eventData.pk.toString(),{
                method: 'GET',
                headers: {
                    Authorization: 'Token '+localStorage.getItem('token')
                }
            })
            .then((response) => response.json())
            .then((data:{asset:Array<TTransaction>,accessory:Array<TAcessoryTransaction>}) => {
                setTransactionsInEvent(data.asset)
                setAccTransactionsInEvent(data.accessory)
            })
        }
    },[reportType])

  return (
    <div style={styles.eventsBackgroundStyle}>
        <Modal open={isLoading} onClose={() => setIsLoading(false)}>
            <Loading />
        </Modal>
        <Header/>
        <div style={styles.pageTopStyle}>
            <Typography style={styles.pageTitleStyle}>{eventData['event_name']}</Typography>
            <div style={styles.actionButtonsStyle}>
                <ActionButton buttonText={'Registar devolução de ativo'} onClick={()=>navigate('/devolução')}/>
                <ActionButton buttonText={'Gerar relatório'} onClick={()=>setReportModal(true)}/>
            </div>
        </div>
        <Paper style={styles.tableContainerStyle}>
            <table style={styles.tableStyle}>
                <tr style={styles.tableHeaderStyle}>
                    <th>Nome</th>
                    <th>Criado por</th>
                    <th>Status</th>
                </tr>
                {rooms.map((evnt) =>                     
                    <tr style={styles.tableBodyRowStyle} onClick={() => handleEnterRoom(evnt['pk'])}>
                        <td>{evnt['room_name']}</td>
                        <td>{evnt['created_by']['name']}</td>
                        <td><StatusCircles status={evnt.room_status}/></td>
                    </tr>
                )}
            </table>
        </Paper>
        <div style={styles.pageTopStyle}>
            <Typography style={styles.pageTitleStyle}>Ativos</Typography>
        </div>
        <Paper style={styles.tableContainerStyle}>
            <table style={styles.tableStyle}>
                <tr style={styles.tableHeaderStyle}>
                    <th>Nome</th>
                    <th>Número de patrimônio</th>
                    <th>Sala</th>
                </tr>
                {assets.map((asset) =>                     
                    <tr style={styles.tableBodyRowStyle}>
                        <td>{asset.asset_name}</td>
                        <td>{asset.asset_id}</td>
                        <td>{asset.room?.room_name || 'Não alocado'}</td>
                    </tr>
                )}
            </table>
        </Paper>
        <div style={styles.pageTopStyle}>
            <Typography style={styles.pageTitleStyle}>Acessórios</Typography>
        </div>
        <Paper style={styles.tableContainerStyle}>
            <table style={styles.tableStyle}>
                <tr style={styles.tableHeaderStyle}>
                    <th>Nome</th>
                    <th>Quantidade</th>
                    <th>Quantidade disponível</th>
                </tr>
                {accessories.map((accessory) =>                     
                    <tr style={styles.tableBodyRowStyle}>
                        <td>{accessory.acessory_name}</td>
                        <td>{accessory.quantity}</td>
                        <td>{accessory.available_quantity}</td>
                    </tr>
                )}
            </table>
        </Paper>
        <div style={styles.pageTopStyle}>
            <Typography style={styles.pageTitleStyle}>Clientes</Typography>
        </div>
        <Paper style={styles.tableContainerStyle}>
            <table style={styles.tableStyle}>
                <tr style={styles.tableHeaderStyle}>
                    <th>Nome</th>
                    <th>Contato</th>
                    <th>Status</th>
                </tr>
                {clientsInEvent.map((client) =>                     
                    <tr style={styles.tableBodyRowStyle}>
                        <td>{client.name}</td>
                        <td>{client.contact}</td>
                        <td><StatusCircles status={client.status || 0} /></td>
                    </tr>
                )}
            </table>
        </Paper>
        <Modal
            open={reportModal}
            onClose={() => setReportModal(false)}
        >
            <Paper style={styles.reportModal}>
                <Typography style={styles.pageTitleStyle}>Gerar relatório</Typography>
                <div style={styles.modalBody}>
                    <Typography>Tipo de relatório</Typography>
                    <div style={styles.modalInput}>
                        <SelectComponentf  
                            label={null} 
                            value={''} 
                            values={reportTypeValues} 
                            onChange={(value:string) => setReportType(value)}
                        />
                    </div>
                    {reportType === 'client'?
                        <div style={styles.modalInput}>
                            <SelectComponentf  
                                label={null} 
                                value={''} 
                                values={transactionsInEvent.reduce((acc, { client }) => ({ ...acc, [client]: client }), {})} 
                                onChange={(value:string) => setClientToGenerateReport(value)}
                            />
                        </div>
                        :
                        <></>
                    }
                    {reportType === 'asset'?
                        <AutocompleteFieldnf 
                            label={''} 
                            value={''} 
                            values={assets.map((asset) => asset.asset_id)} 
                            onChangeCallback={(value:string) => setClientToGenerateReport(value)}
                            style={{minWidth: '20vw'}}
                        />
                        :
                        <></>
                    }
                    <div style={styles.modalButton}>
                        <ActionButton buttonText={'Gerar'} onClick={()=>setReport(true)}/>
                    </div>
                </div>
            </Paper>
        </Modal>
        <Modal
            open={report}
            onClose={() => setReport(false)}
            style={styles.pdfModal}
        >   
        <>
            {reportType === 'client'?
                <EventReportByClient 
                    client={clientToGenerateReport}
                    transactions={transactionsInEvent.filter((obj) => obj.client === clientToGenerateReport)}
                    accTransactions={accTransactionsInEvent.filter((obj) => obj.client === clientToGenerateReport)}
                    event={eventData}
                    assets={assets}
                    accessories={accessories}
                    rooms={rooms}
                />
            :
                <></>
            }
            {reportType === 'asset'?
                <EventReportByAsset
                    client={clientToGenerateReport}
                    transactions={transactionsInEvent.filter((obj) => obj.asset === clientToGenerateReport)}
                    event={eventData}
                    assets={assets}
                    accessories={accessories}
                    rooms={rooms}
                    clients={transactionsInEvent.map((obj) => obj.client)}
                />   
            :
                <></>
            }
            {reportType === 'general'?
                <EventReport
                    transactions={transactionsInEvent}
                    accTransactions={accTransactionsInEvent}
                    event={eventData}
                    assets={assets}
                    accessories={accessories}
                    rooms={rooms}
                    clients={transactionsInEvent.map((obj) => obj.client)}
                />   
            :
                <></>
            }
        </>
        </Modal>
    </div>
  )
}