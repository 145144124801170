import { Autocomplete, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'


interface Props {
    name: string
    label: string | null
    value: string
    control: any
    values: string[]
    style: Object
  }


export default function AutocompleteField(props:Props) {
  return (
    <Controller
        name={props.name}
        control={props.control}
        render={({ field: {onChange} }) => 
            <Autocomplete
                options={props.values}
                style={props.style}
                renderInput={(params) => 
                  <TextField {...params} 
                    style={props.style} 
                    label={''} 
                    onChange={(e) => {onChange(e.target.value)}}
                  />
                }
                sx={{"& .MuiOutlinedInput-root": {"&.Mui-focused fieldset": {borderColor: "#025482"}}, "& label.Mui-focused": {color: "#025482"}}}
                onChange={(e, value) => onChange(value)}
                noOptionsText='Nenhuma opção disponível'
                value={props.value}
            />
        }
    />
  )
}
