import CSS from 'csstype'

export const newEventBackgroundStyle:CSS.Properties = {
    'position': 'absolute',
    'width': '100vw',
    'height': '100vh',
    'display': 'flex',
    'flexDirection': 'column'
}

export const pageTopStyle:CSS.Properties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '96vw',
    marginLeft: '2vw'
}

export const pageTitleStyle:CSS.Properties = {
    'color': '#000',
    fontSize: '24px',
    marginTop: '1vh'
}

export const pageSubtitleStyle:CSS.Properties = {
    'color': '#000',
    fontSize: '18px',
    marginTop: '1vh',
    marginBottom: '1vh'
}

export const formStyle: CSS.Properties = {
    width: '96vw',
    height: '75vh',
    backgroundColor: '#FFF',
    marginLeft: '2vw',
    display: 'flex',
    alignContent: 'left',
    flexDirection: 'column',
    alignItems: 'left'
}

export const formContainerStyle: CSS.Properties = {
    width: '96vw',
    height: '75vh',
    backgroundColor: '#FFF',
    marginLeft: '2vw',
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'scroll'
}

export const formRowStyle: CSS.Properties = {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'left',
    marginTop: '2vh',
    width: '20vw',

}
export const formColumnStyle: CSS.Properties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'left',
    marginTop: '2vh',
    width: '50vw'
}

export const showContainer: CSS.Properties = {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '2vw',
    width: '50vw'
}

export const formLabelStyle: CSS.Properties = {
    marginRight: '5vw',
}

export const inputStyle: CSS.Properties = {
    width: '25vw'
}

export const listItemStyle:CSS.Properties = {
    display: 'flex',
    flexDirection: 'row',
    padding: '2vh',
    width: '40vw',
    justifyContent: 'space-between',
    margin: '1vh'
}

export const itemsListStyle: CSS.Properties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '50vh',
    width: '50vw',
    overflowY: 'scroll'
}

export const boldText: CSS.Properties = {
    fontWeight: 'bold'
}

export const row:CSS.Properties = {
    width: '94vw',
    backgroundColor: '#FFF',
    display: 'flex',
    alignContent: 'left',
    flexDirection: 'row'
}

export const assetFieldStyle: CSS.Properties = {
    minWidth: '20vw'
}

export const pdfModal: CSS.Properties = {
    marginLeft: '15vw',
    marginTop: '5vh',
    height: '90vh',
    width: '70vw'
}

export const squareButton: CSS.Properties = {
    height: '4vw',
    width: '4vw',
    margin: '1vw',
    backgroundColor: '#025482',
    
}

export const divider: CSS.Properties = {
    marginTop: '2vh',
    marginBottom: '2vh'
}

export const helperText: CSS.Properties = {
    marginLeft: '1vw',
    color: '#808080',
}

export const buttonContainer: CSS.Properties = {
    margin: '2vh',
}

export const switchStyle: CSS.Properties = {
    color: '#025482'
}

export const cameraIcon: CSS.Properties = {
    marginLeft: '1vw'
}