import React, { useEffect, useState } from 'react'
import * as styles from './styles'
import Header from '../../components/Header'
import { ButtonBase, Modal, Paper, Typography, Snackbar, Alert } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ActionButton from '../../components/ActionButton'
import { API_URL } from '../../constants'
import { formatedDate } from '../../utils/dateFormat'
import { useNavigate } from 'react-router-dom'
import InTableButton from '../../components/InTableButton';
import { TEvent } from '../../types/interfaces';
import ConfirmModal from '../../components/ConfirmModal';


export default function Events() {
    const navigate = useNavigate()
    const [events, setEvents] = useState<Array<TEvent>>([])
    const [update, setUpdate] = useState<number>(0)
    const [errorAlert, setErrorAlert] = useState<boolean>(false)
    const [confirmModal, setConfirmModal] = useState<boolean>(false)
    const [confirmModalData, setConfirmModalData] = useState<{title: string, message: string, cancelCallback: Function, okCallback: Function}>()

    const handleEnterEvent = (pk: string) => {
        sessionStorage.setItem('event',pk)
        navigate('/evento')
    }

    const confirmInactivateEvent = (pk:string) => {
        setConfirmModal(true)
        setConfirmModalData({
            title: 'Inativar evento?', 
            message: 'Esta ação é irreversível', 
            cancelCallback: () => {setConfirmModal(false)}, 
            okCallback: () => {handleInactivateEvent(pk);setConfirmModal(false)}
        })
    }

    const confirmDeleteEvent = (evnt: TEvent) => {
        setConfirmModal(true)
        setConfirmModalData({
            title: 'Excluir evento?', 
            message: 'Esta ação é irreversível', 
            cancelCallback: () => {setConfirmModal(false)}, 
            okCallback: () => {handleDeleteEvent(evnt);setConfirmModal(false)}
        })
    }

    const handleInactivateEvent = (pk: string) => {
        fetch(API_URL+'/changeEventStatus', {
            method: 'POST',
            headers: {
                'Content-Type':  'application/json',
                Authorization: 'Token '+localStorage.getItem('token')
            },
            body: JSON.stringify({
                event_pk: pk,
            })
        })
        .then((response) => {
            if(response.status === 200) {
                setUpdate(n => n+1)
            }
            else {
                setErrorAlert(true)
            }
        })
    }

    const handleDeleteEvent = (evnt: TEvent) => {
        fetch(API_URL+'/deleteEvent',{
            method: 'POST',
            headers: {
                'Content-Type':  'application/json',
                Authorization: 'Token '+localStorage.getItem('token')
            },
            body: JSON.stringify({
                event_pk: evnt.pk
            })
        }) 
        .then(response => {
            if(response.ok) {
                setUpdate(n => n+1)
            }
        })
    }

    useEffect(() => {
        fetch(API_URL+'/getEventsList',{
            method: 'GET',
            headers: {
                Authorization: 'Token '+localStorage.getItem('token')
            }
        })
        .then((response) => {
            if (response.status == 401) {
                navigate('/login')
            }
            else {
                response.json()
                .then((data:Array<TEvent>) => setEvents(data))
            }
        })
    },[update])


  return (
    <div style={styles.eventsBackgroundStyle}>
        <Header/>
        <div style={styles.pageTopStyle}>
            <Typography style={styles.pageTitleStyle}>Eventos</Typography>
            <ActionButton buttonText={'Novo Evento'} onClick={()=>{navigate('/novo-evento')}}/>
        </div>
        <Paper style={styles.tableContainerStyle}>
            <table style={styles.tableStyle}>
                <tr style={styles.tableHeaderStyle}>
                    <th>Nome</th>
                    <th>OS</th>
                    <th>Criado em</th>
                    <th>Criado por</th>
                    <th>Status</th>
                    <th></th>
                    <th></th>
                </tr>
                {events.map((evnt) =>                     
                    <tr style={styles.tableBodyRowStyle}>
                        <InTableButton backgroundColor='#E6E6E6' onClick={() => handleEnterEvent(evnt['pk'])}>
                            <td>{evnt['event_name']}</td>
                        </InTableButton>
                        <td>{evnt.event_id}</td>
                        <td>{formatedDate(evnt['created_at'])}</td>
                        <td>{evnt['created_by']['name']}</td>
                        <InTableButton backgroundColor={evnt.event_status == 0? '#FDEDED' : '#EDF7ED'} onClick={() => evnt.event_status === 1? confirmInactivateEvent(evnt['pk']) : handleInactivateEvent(evnt['pk'])}>
                            <td>{evnt.event_status == 0? 'Inativo':'Ativo'}</td>
                        </InTableButton>
                        <td onClick={() => confirmDeleteEvent(evnt)}><DeleteIcon /></td>
                        <td onClick={() => navigate('/novo-evento',{state: {eventToEdit: evnt.event_id}})}><EditIcon /></td>
                    </tr>
                )}
            </table>
        </Paper>
        <Snackbar open={errorAlert} autoHideDuration={2000} onClose={() => setErrorAlert(false)}>
            <Alert severity="error">Ocorreu um erro!</Alert>
        </Snackbar>
        <Modal
            open={confirmModal}
            onClose={() => setConfirmModal(false)}
        >
            <ConfirmModal
                title={confirmModalData?.title}
                message={confirmModalData?.message}
                cancelCallback={confirmModalData?.cancelCallback}
                okCallback={confirmModalData?.okCallback}
            />
        </Modal>
    </div>
  )
}
