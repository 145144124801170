import React, { useEffect } from 'react'
import {Html5QrcodeScanner} from "html5-qrcode";
import { Paper } from '@mui/material';

interface Props {
    decodedText: Function
}

export default function BarcodeReader(props: Props) {
    function onScanSuccess(decodedText: any, decodedResult: any) {
        // handle the scanned code as you like, for example:
        props.decodedText(decodedText)

    }
      
    function onScanFailure(error: any) {
        // handle scan failure, usually better to ignore and keep scanning.
        // for example:
        console.warn(`Code scan error = ${error}`);
    }
    
    useEffect(() => {
        let html5QrcodeScanner = new Html5QrcodeScanner(
            "reader",
            { fps: 10, qrbox: {width: 250, height: 250} },
            /* verbose= */ false
        );
        html5QrcodeScanner.render(onScanSuccess, onScanFailure);
        return () => {
            html5QrcodeScanner.clear()
        };
    },[])
  return (
    <Paper style={{width: '60vw', marginLeft: '20vw'}}>
        <div id="reader" style={{width: '50vw', marginLeft: '5vw', backgroundColor: '#FFF'}}></div>
    </Paper>
  )
}
