import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";

import Login from "./pages/Login";
import Events from "./pages/Events";
import Event from "./pages/Event";
import Redirect from "./components/Redirect";
import Room from "./pages/Room";
import NewEvent from "./pages/NewEvent";
import NewAsset from "./pages/NewAsset";
import Assets from "./pages/Assets";
import Clients from "./pages/Clients";
import NewClient from "./pages/NewClient";
import NewTransaction from "./pages/newTransaction";
import AddUser from "./pages/AddUser";
import Users from "./pages/Users";
import SimpleReturn from "./pages/SimpleReturn";

export function Router() {
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Redirect/>} />
                <Route path="/login" element={<Login/>} />
                <Route path="/eventos" element={<Events/>} />
                <Route path="/evento" element={<Event/>} />
                <Route path="/sala" element={<Room/>} />
                <Route path="/novo-evento" element={<NewEvent/>} />
                <Route path="/novo-ativo" element={<NewAsset/>} />
                <Route path="/ativos" element={<Assets/>} />
                <Route path="/clientes" element={<Clients/>} />
                <Route path="/novo-cliente" element={<NewClient/>} />
                <Route path="/nova-transação" element={<NewTransaction/>} />
                <Route path="/novo-usuario" element={<AddUser/>} />
                <Route path="/usuarios" element={<Users/>} />
                <Route path="/devolução" element={<SimpleReturn/>} />
            </Routes>
        </BrowserRouter>
    )
}